<template>
  <div>
    <v-dialog v-model="PDFViewDialog" persistent max-width="1000px">
      <v-card class="overflow-y-auto">
        <v-toolbar dark color="primary" dense class="elevation-0">
          <div>PDF Preview<span class="ml-2">({{StoreObj.template_name}})</span></div>
          <v-spacer></v-spacer>
          <v-btn icon @click="PDFPreviewDialogEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="mt-4">
          <v-card>
            <div class="px-4 py-2" v-html="final_html"></div>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    StoreObj:Object,
    PDFViewDialog: Boolean,
    template_html: String,
    newHTML: String,
  },
  data: () => ({
    Toggle: 0,
    final_html: "",
  }),
  watch: {
    PDFViewDialog(val) {
      if (val == true) {
        if (this.newHTML != "" && this.newHTML != undefined && this.newHTML != null) {
          this.final_html = this.newHTML;
        } else {
          this.final_html = this.template_html;
        }
      }
    },
  },
  methods: {
    PDFPreviewDialogEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
<style>
.ql-align-center {
  text-align: center;
}
.ql-align-left {
  text-align: left;
}
.ql-align-right {
  text-align: right;
}
.ql-align-justify {
  text-align: justify;
}
.main_div {
  width:700px;
  position: relative;
  height: 1060px;
}
.sub_div {
  position: absolute;
  bottom: 0px;
}
.margin-top{
  margin-top: 150px;
}
</style>
