<template>
  <div>
    <Overlay :overlay="overlay" />
    <AddIssuerTypes
      :StoreObj="StoreObj"
      :dialogAddIssuerTypes="dialogAddIssuerTypes"
      @clicked="AddIssuerTypesDialogEmit"
    />
    <ActivateDeactivateIssuerTypeDialog
      :StoreObj="StoreObj"
      :dialogActivateInactivateIssuerType="dialogActivateInactivateIssuerType"
      @clicked="dialogActivateInactivateIssuerTypeEmit"
    />
    <v-card class="elevation-0">
      <v-row no-gutters>
        <v-col cols="12" md="6" sm="12">
          <v-toolbar class="elevation-0">
            <div class="PrimaryFontColor heading1">Issuer Types</div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" md="6" sm="12">
          <v-toolbar class="elevation-0">
            <v-spacer></v-spacer>
            <v-tooltip bottom color="#474747">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  v-on="on"
                  v-bind="attrs"
                  color="primary"
                  @click="dialogAddIssuerTypes = true"
                  ><v-icon small class="mr-1">mdi-plus</v-icon>Create Issuer
                  Type</v-btn
                >
              </template>
              <span>Create New Issuer Type</span>
            </v-tooltip>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="pt-0">
        <v-data-table
          dense
          class="elevation-0"
          :headers="issuerTypeHeaders"
          :items="GetAllIssuerTypesList"
          :disable-sort="$vuetify.breakpoint.xs ? true : false"
          :hide-default-footer="GetAllIssuerTypesList.length == 0"
        >
          <template v-slot:[`item.sl_no`]="{ item }">
            <div class="FontSize">
              {{ GetAllIssuerTypesList.indexOf(item) + 1 }}
            </div>
          </template>
          <template v-slot:[`item.issuer_type`]="{ item }">
            <div class="FontSize">
              {{ item.issuer_type }}
            </div>
          </template>
          <template v-slot:[`item.issuer_type_created_on`]="{ item }">
            <div class="FontSize">
              {{ new Date(item.issuer_type_created_on).toLocaleString() }}
            </div>
          </template>
          <template v-slot:[`item.issuer_type_status`]="{ item }">
            <div
              class="FontSize font-weight-bold"
              :class="
                item.issuer_type_status == 'ACTIVE'
                  ? 'GreenHeader'
                  : 'RedHeader'
              "
            >
              {{ item.issuer_type_status }}
            </div>
          </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  :color="item.issuer_type_status == 'ACTIVE' ? 'red' : 'green'"
                  @click="checkItem(item, 'ACTION')"
                  ><v-icon>{{
                    item.issuer_type_status == "ACTIVE"
                      ? "mdi-close"
                      : "mdi-check"
                  }}</v-icon></v-btn
                >
              </template>
              <span>{{
                item.issuer_type_status == "ACTIVE"
                  ? `Deactivate ${item.issuer_type}`
                  : `Activate ${item.issuer_type}`
              }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import AddIssuerTypes from "@/components/IssuerTypes/Dialogs/AddIssuerTypes.vue";
import ActivateDeactivateIssuerTypeDialog from "@/components/IssuerTypes/Dialogs/ActivateDeactivateIssuerTypeDialog.vue";
import { GetAllIssuerTypes } from "@/mixins/GetAllIssuerTypes.js";
export default {
  components: {
    Overlay,
    AddIssuerTypes,
    ActivateDeactivateIssuerTypeDialog,
  },
  mixins: [GetAllIssuerTypes],
  data: () => ({
    dialogAddIssuerTypes: false,
    dialogActivateInactivateIssuerType: false,
    issuerTypeHeaders: [
      {
        text: "Issuer Type",
        value: "issuer_type",
      },
      {
        text: "Created On",
        value: "issuer_type_created_on",
      },
      {
        text: "Status",
        value: "issuer_type_status",
      },
      {
        text: "Actions",
        value: "Actions",
      },
    ],
    StoreObj: {},
  }),
  mounted() {
    this.GetAllIssuerTypesMethod();
  },
  methods: {
    checkItem(item) {
      this.StoreObj = item;
      this.dialogActivateInactivateIssuerType = true;
    },
    dialogActivateInactivateIssuerTypeEmit(Toggle) {
      this.dialogActivateInactivateIssuerType = false;
      if (Toggle == 2) {
        this.GetAllIssuerTypesMethod();
      }
    },
    AddIssuerTypesDialogEmit(Toggle) {
      this.dialogAddIssuerTypes = false;

      if (Toggle) {
        this.GetAllIssuerTypesMethod();
      }
    },
  },
};
</script>
