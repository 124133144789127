<template>
  <div>
    <Overlay :overlay="overlay" />
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <PDFPreviewDialog
      :StoreObj="StoreObj"
      :PDFViewDialog="PDFViewDialog"
      :template_html="template_html"
      @clicked="PDFPreviewDialogEmit"
    />
    <DeletePDFTemplateDialog
      :StoreObj="StoreObj"
      :dialogDeletePDFTemplate="dialogDeletePDFTemplate"
      @clicked="dialogDeletePDFTemplateEmit"
    />
    <SavePDFWarningDialog
      :template_html="template_html"
      :StoreObj="StoreObj"
      :settings="settings"
      :templateContentItems="templateContentItems"
      :dialogSavePDFWarning="dialogSavePDFWarning"
      @clicked="dialogSavePDFWarningEmit"
    />
    <SignatureWarning
      :dialogSignatureWarning="dialogSignatureWarning"
      @clicked="dialogSignatureWarningEmit"
    />
    <v-card class="elevation-0" v-if="current_view == 'LIST'">
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <div class="heading1 PrimaryFontColor">Templates</div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <v-spacer></v-spacer>
            <v-tooltip bottom color="#474747">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  v-on="on"
                  v-bind="attrs"
                  @click="
                    (current_view = 'CREATE'), (StoreObj.action = 'CREATE')
                  "
                  color="secondary"
                  class="borderRadius"
                  ><v-icon small class="mr-1">mdi-plus</v-icon>Design
                  Template</v-btn
                >
              </template>
              <span>Design New Template</span>
            </v-tooltip>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="py-0">
        <v-card outlined>
          <v-data-table
            dense
            class="elevation-0"
            :headers="GetAllBMSPDFsListHeaders"
            :items="GetAllBMSPDFsList"
            :no-data-text="noDataText"
            :hide-default-footer="GetAllBMSPDFsList.length == 0"
            :disable-sort="$vuetify.breakpoint.xs ? true : false"
          >
            <template v-slot:[`item.sl_no`]="{ item }">
              <div class="FontSize">
                {{ GetAllBMSPDFsList.indexOf(item) + 1 }}
              </div>
            </template>
            <template v-slot:[`item.template_name`]="{ item }">
              <div class="FontSize">{{ item.template_name }}</div>
            </template>
            <template v-slot:[`item.template_created_on`]="{ item }">
              <div class="FontSize">
                {{ new Date(item.template_created_on).toLocaleString() }}
              </div>
            </template>
            <template v-slot:[`item.template_type`]="{ item }">
              <div class="FontSize">
                {{ item.is_editable == true ? "PORTAL" : "SURECREDS" }}
              </div>
            </template>
            <template v-slot:[`item.Actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    x-small
                    color="blue"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    @click="checkItem(item, 'PREVIEW')"
                    >Preview</v-btn
                  >
                </template>
                <span align="center">Preview {{ item.template_name }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-2"
                    x-small
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    @click="checkItem(item, 'EDIT')"
                    >Edit</v-btn
                  >
                </template>
                <span align="center">Edit {{ item.template_name }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-2"
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    x-small
                    @click="checkItem(item, 'DELETE')"
                    >Delete</v-btn
                  >
                </template>
                <span align="center">Delete {{ item.template_name }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
    </v-card>
    <DesignTemplateCard
      :TemplateStoreObj="StoreObj"
      v-if="current_view == 'CREATE'"
      @clicked="designTemplateEmit"
    />
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import Snackbar from "@/components/Extras/Snackbar.vue";
import PDFPreviewDialog from "@/components/TemplateDesigner/Dialogs/PDFPreviewDialog.vue";
import DeletePDFTemplateDialog from "@/components/TemplateDesigner/Dialogs/DeletePDFTemplateDialog.vue";
import SavePDFWarningDialog from "@/components/TemplateDesigner/Dialogs/SavePDFWarningDialog.vue";
import DesignTemplateCard from "@/components/TemplateDesigner/Cards/DesignTemplateCard.vue";
import SignatureWarning from "@/components/TemplateDesigner/Dialogs/SignatureWarning.vue";
import { DesignTemplate } from "@/mixins/DesignTemplate.js";
import { GetAllBMSPDFs } from "@/mixins/GetAllBMSPDFs.js";
import { FormPDFTemplate } from "@/mixins/FormPDFTemplate.js";
export default {
  mixins: [GetAllBMSPDFs, DesignTemplate, FormPDFTemplate],
  components: {
    DeletePDFTemplateDialog,
    SavePDFWarningDialog,
    PDFPreviewDialog,
    DesignTemplateCard,
    SignatureWarning,
    Snackbar,
    Overlay,
  },
  data: () => ({
    overlay: false,
    PDFViewDialog: false,
    SnackBarComponent: {},
    TemplateTableExpand: false,
    dialogDeletePDFTemplate: false,
    current_view: "LIST",
    template_html: "",
    StoreObj: {},
    GetAllBMSPDFsList: [],
    GetAllBMSPDFsListHeaders: [
      { text: "SL NO.", value: "sl_no" },
      { text: "Name", value: "template_name" },
      { text: "Created On", value: "template_created_on" },
      { text: "Actions", value: "Actions" },
    ],
    settings: {},
    templateContentItems: [],
    dialogSavePDFWarning: false,
    dialogSignatureWarning: false,
    templateItems: [],
  }),
  mounted() {
    this.GetAllBMSPDFsMethod();
  },
  methods: {
    dialogSignatureWarningEmit() {
      this.dialogSignatureWarning = false;
    },
    dialogSavePDFWarningEmit(Toggle, ResultObject) {
      this.dialogSavePDFWarning = false;
      if (Toggle == 2) {
        this.GetAllBMSPDFsMethod();
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: ResultObject.Status_Message,
        };
      }
    },
    designTemplateEmit(Toggle, ResultObject) {
      this.current_view = "LIST";
      this.GetAllBMSPDFsMethod();
      if (Toggle == 2) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: ResultObject.Status_Message,
        };
      }
    },
    PDFPreviewDialogEmit() {
      this.PDFViewDialog = false;
    },
    dialogDeletePDFTemplateEmit(Toggle) {
      this.dialogDeletePDFTemplate = false;
      if (Toggle == 2) {
        this.GetAllBMSPDFsMethod();
      }
    },
    async deciderMethod(item, action) {
      if (action == "EDIT") {
        this.current_view = "CREATE";
      } else if (action == "DELETE") {
        this.dialogDeletePDFTemplate = true;
      }
      if (action == "PREVIEW") {
        this.PDFViewDialog = true;
        await this.formPDFMethod();
        this.StoreObj.template_html = this.template_html;
      }
      if (action == "COPY") {
        this.dialogSavePDFWarning = true;
      }
    },
    async checkItem(item, action) {
      this.StoreObj = item;
      this.templateItems = this.StoreObj.settings;
      this.StoreObj.action = action;
      if (
        this.StoreObj.settings.filter(
          (item) => item.type == "SIGNATURE" && item.content_present == true
        ).length == 0
      ) {
        await this.formPDFMethod();
        await this.deciderMethod(this.StoreObj, this.StoreObj.action);
      } else {
        this.deciderMethod(this.StoreObj, this.StoreObj.action);
      }
    },
  },
};
</script>
