<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog
      persistent
      :max-width="current_view != 'ENTER_PASSWORD' ? '1200px' : '400px'"
      v-model="dialogIssuerDetails"
    >
      <v-card>
        <v-toolbar dark color="secondary" dense class="elevation-0">
          <v-icon small class="mr-2">{{
            StoreObj.action == "CREATE" ? "mdi-plus" : "mdi-information"
          }}</v-icon>
          <div>
            {{
              StoreObj.action == "CREATE" ? "Create Issuer" : "Issuer Details"
            }}
          </div>
          <v-spacer></v-spacer>
          <v-btn icon @click="IssuerDetailsDialogEmit((Toggle = 1))"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text v-if="current_view == 'CREATE'">
          <v-form ref="form">
            <v-row no-gutters>
              <v-col cols="12" xs="12" sm="12" md="9">
                <v-row>
                  <v-col cols="12" sm="12" md="12" xs="12" class="pt-6">
                    <div class="heading3 mx-1 font-weight-bold">
                      Issuer Details :
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" xs="12" class="py-0">
                    <!-- StoreObj.new_details['issuer_name'] -->
                    <v-text-field
                      outlined
                      dense
                      :background-color="
                        StoreObj.existing_details.issuer_name !==
                        StoreObj.new_details.issuer_name
                          ? `grey lighten-3`
                          : ''
                      "
                      :readonly="
                        StoreObj.action == 'VIEW' && modify_bool == false
                      "
                      class=" field_height field_label_size FontSize"
                      label="Issuer Name*"
                      :rules="[(v) => !!v || 'Required']"
                      v-model="StoreObj.new_details.issuer_name"
                    ></v-text-field>
                    <div
                      class="mt-n6 FontSizeSmall font-weight-bold font-weight-black red--text"
                      v-if="
                        StoreObj.existing_details.issuer_name !==
                          StoreObj.new_details.issuer_name
                      "
                    >
                      Changed
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" xs="12" class="py-0">
                    <!-- StoreObj.new_details['country'] -->
                    <v-select
                      outlined
                      dense
                      class=" field_height field_label_size FontSize"
                      label="Country *"
                      :readonly="
                        StoreObj.action == 'VIEW' && modify_bool == false
                      "
                      :background-color="
                        StoreObj.existing_details.country !==
                        StoreObj.new_details.country
                          ? `grey lighten-3`
                          : ''
                      "
                      :items="GetAllSupportedCountryList"
                      item-value="country_name"
                      item-text="country_name"
                      :rules="[(v) => !!v || 'Country is required']"
                      v-model="StoreObj.new_details.country"
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.country_name
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-select>
                    <!-- StoreObj.new_details['country'] -->
                    <div
                      class="mt-n6 FontSizeSmall font-weight-bold font-weight-black red--text"
                      v-if="
                        StoreObj.existing_details.country !==
                          StoreObj.new_details.country
                      "
                    >
                      Changed
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" xs="12" class="py-0">
                    <v-select
                      outlined
                      dense
                      :readonly="
                        StoreObj.action == 'VIEW' && modify_bool == false
                      "
                      :background-color="
                        StoreObj.existing_details.identification_type !==
                        StoreObj.new_details.identification_type
                          ? `grey lighten-3`
                          : ''
                      "
                      class=" field_height field_label_size FontSize"
                      label="Please Select Identification Type"
                      :items="identificationTypeItems"
                      v-model="StoreObj.new_details.identification_type"
                    ></v-select>
                    <!-- StoreObj.new_details['identification_type'] -->
                    <div
                      class="mt-n6 FontSizeSmall font-weight-bold font-weight-black red--text"
                      v-if="
                        StoreObj.existing_details.identification_type !==
                          StoreObj.new_details.identification_type
                      "
                    >
                      Changed
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" xs="12" class="py-0">
                    <!-- StoreObj.new_details['identification_number'] -->
                    <v-text-field
                      outlined
                      dense
                      :readonly="
                        StoreObj.action == 'VIEW' && modify_bool == false
                      "
                      :background-color="
                        StoreObj.existing_details.identification_number !==
                        StoreObj.new_details.identification_number
                          ? `grey lighten-3`
                          : ''
                      "
                      class=" field_height field_label_size FontSize"
                      label="Business Identification No. *"
                      :rules="[(v) => !!v || 'Required']"
                      v-model="StoreObj.new_details.identification_number"
                    ></v-text-field>
                    <div
                      class="mt-n6 FontSizeSmall font-weight-bold font-weight-black red--text"
                      v-if="
                        StoreObj.existing_details.identification_number !==
                          StoreObj.new_details.identification_number
                      "
                    >
                      Changed
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" xs="12" class="pt-0">
                    <div class="heading3 mx-1 font-weight-bold">
                      Registered Business Address :
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" xs="12" class="py-0">
                    <!-- StoreObj.new_details['issuer_address_1'] -->
                    <v-text-field
                      outlined
                      dense
                      :readonly="
                        StoreObj.action == 'VIEW' && modify_bool == false
                      "
                      :background-color="
                        StoreObj.existing_details.issuer_address_1 !==
                        StoreObj.new_details.issuer_address_1
                          ? `grey lighten-3`
                          : ''
                      "
                      class=" field_height field_label_size FontSize"
                      label="Address 1 *"
                      :rules="[(v) => !!v || 'Required']"
                      v-model="StoreObj.new_details.issuer_address_1"
                    ></v-text-field>
                    <div
                      class="mt-n6 FontSizeSmall font-weight-bold font-weight-black red--text"
                      v-if="
                        StoreObj.existing_details.issuer_address_1 !==
                          StoreObj.new_details.issuer_address_1
                      "
                    >
                      Changed
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" xs="12" class="py-0">
                    <!-- StoreObj.new_details['issuer_address_2'] -->
                    <v-text-field
                      outlined
                      dense
                      :readonly="
                        StoreObj.action == 'VIEW' && modify_bool == false
                      "
                      :background-color="
                        StoreObj.existing_details.issuer_address_2 !==
                        StoreObj.new_details.issuer_address_2
                          ? `grey lighten-3`
                          : ''
                      "
                      class=" field_height field_label_size FontSize"
                      label="Address 2 *"
                      :rules="[(v) => !!v || 'Required']"
                      v-model="StoreObj.new_details.issuer_address_2"
                    ></v-text-field>
                    <div
                      class="mt-n6 FontSizeSmall font-weight-bold font-weight-black red--text"
                      v-if="
                        StoreObj.existing_details.issuer_address_2 !==
                          StoreObj.new_details.issuer_address_2
                      "
                    >
                      Changed
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="3" xs="12" class="py-0">
                    <!-- StoreObj.new_details['state'] -->
                    <v-text-field
                      outlined
                      dense
                      :readonly="
                        StoreObj.action == 'VIEW' && modify_bool == false
                      "
                      :background-color="
                        StoreObj.existing_details.state !==
                        StoreObj.new_details.state
                          ? `grey lighten-3`
                          : ''
                      "
                      class=" field_height field_label_size FontSize"
                      label="State *"
                      :rules="[(v) => !!v || 'Required']"
                      v-model="StoreObj.new_details.state"
                    ></v-text-field>
                    <div
                      class="mt-n6 FontSizeSmall font-weight-bold font-weight-black red--text"
                      v-if="
                        StoreObj.existing_details.state !==
                          StoreObj.new_details.state
                      "
                    >
                      Changed
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="3" xs="12" class="py-0">
                    <!-- StoreObj.new_details['city'] -->
                    <v-text-field
                      outlined
                      dense
                      :readonly="
                        StoreObj.action == 'VIEW' && modify_bool == false
                      "
                      class=" field_height field_label_size FontSize"
                      label="City *"
                      :items="citiesList"
                      item-text="name"
                      item-value="name"
                      :rules="[(v) => !!v || 'Required']"
                      v-model="StoreObj.new_details.city"
                      :background-color="
                        StoreObj.existing_details.city !==
                        StoreObj.new_details.city
                          ? `grey lighten-3`
                          : ''
                      "
                    ></v-text-field>
                    <div
                      class="mt-n6 FontSizeSmall font-weight-bold font-weight-black red--text"
                      v-if="
                        StoreObj.existing_details.city !==
                          StoreObj.new_details.city
                      "
                    >
                      Changed
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="3" xs="12" class="py-0">
                    <!-- StoreObj.new_details['postal_code'] -->
                    <v-text-field
                      outlined
                      dense
                      :readonly="
                        StoreObj.action == 'VIEW' && modify_bool == false
                      "
                      class=" field_height field_label_size FontSize"
                      label="Postal Code *"
                      :background-color="
                        StoreObj.existing_details.postal_code !==
                        StoreObj.new_details.postal_code
                          ? `grey lighten-3`
                          : ''
                      "
                      :rules="[
                        (v) => !!v || 'Required',
                        (v) =>
                          /^(?=[a-zA-Z0-9@._%+-]{0,30}$)[0-9]{0,6}$/.test(v) ||
                          'Postal Code Is Invalid',
                      ]"
                      v-model="StoreObj.new_details.postal_code"
                    ></v-text-field>
                    <div
                      class="mt-n6 FontSizeSmall font-weight-bold font-weight-black red--text"
                      v-if="
                        StoreObj.existing_details.postal_code !==
                          StoreObj.new_details.postal_code
                      "
                    >
                      Changed
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" xs="12" class="pt-0">
                    <div class="heading3 mx-1 font-weight-bold">
                      Business Contact Details :
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" xs="12" class="py-0">
                    <!-- StoreObj.new_details['contact_user_name'] -->
                    <v-text-field
                      outlined
                      dense
                      :readonly="StoreObj.action == 'VIEW'"
                      class=" field_height field_label_size FontSize"
                      label="Name *"
                      :rules="[(v) => !!v || 'Required']"
                      v-model="StoreObj.new_details.contact_user_name"
                      :background-color="
                        StoreObj.existing_details.contact_user_name !==
                        StoreObj.new_details.contact_user_name
                          ? `grey lighten-3`
                          : ''
                      "
                    ></v-text-field>
                    <div
                      class="mt-n6 FontSizeSmall font-weight-bold font-weight-black red--text"
                      v-if="
                        StoreObj.existing_details.contact_user_name !==
                          StoreObj.new_details.contact_user_name
                      "
                    >
                      Changed
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" xs="12" class="py-0">
                    <!-- StoreObj.new_details['contact_email_id'] -->
                    <v-text-field
                      outlined
                      dense
                      :readonly="StoreObj.action == 'VIEW'"
                      class=" field_height field_label_size FontSize"
                      label="Email ID *"
                      :rules="[(v) => !!v || 'Required']"
                      v-model="StoreObj.new_details.contact_email_id"
                      :background-color="
                        StoreObj.existing_details.contact_email_id !==
                        StoreObj.new_details.contact_email_id
                          ? `grey lighten-3`
                          : ''
                      "
                    ></v-text-field>
                    <div
                      class="mt-n6 FontSizeSmall font-weight-bold font-weight-black red--text"
                      v-if="
                        StoreObj.existing_details.contact_email_id !==
                          StoreObj.new_details.contact_email_id
                      "
                    >
                      Changed
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="2" xs="12" class="py-0">
                    <!-- StoreObj.new_details['country_code'] -->
                    <v-select
                      outlined
                      dense
                      :readonly="
                        StoreObj.action == 'VIEW' && modify_bool == false
                      "
                      :items="countryList"
                      item-value="dial_code"
                      item-text="dial_code"
                      class=" field_height field_label_size FontSize"
                      label="Country Code *"
                      :rules="[(v) => !!v || 'Required']"
                      v-model="StoreObj.new_details.country_code"
                      :background-color="
                        StoreObj.existing_details.country_code !==
                        StoreObj.new_details.country_code
                          ? `grey lighten-3`
                          : ''
                      "
                    >
                      <template slot="selection" slot-scope="{ item }">
                        {{ item.dial_code }}
                      </template>
                      <template slot="item" slot-scope="{ item }"
                        >{{ item.name }}({{ item.dial_code }})
                      </template>
                    </v-select>
                    <div
                      class="mt-n6 FontSizeSmall font-weight-bold font-weight-black red--text"
                      v-if="
                        StoreObj.existing_details.country_code !==
                          StoreObj.new_details.country_code
                      "
                    >
                      Changed
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="2" xs="12" class="py-0">
                    <!-- StoreObj.new_details['contact_number'] -->
                    <v-text-field
                      outlined
                      dense
                      :readonly="
                        StoreObj.action == 'VIEW' && modify_bool == false
                      "
                      class=" field_height field_label_size FontSize"
                      label="Contact Number *"
                      :rules="[
                        (v) =>
                          /^(?=[a-zA-Z0-9@._%+-]{0,30}$)[0-9 +-]{0,64}$/.test(
                            v
                          ) || 'Invalid',
                        (v) => (v && v.length < 11) || 'Invalid',
                        (v) => (v && v.length > 9) || 'Invalid',
                      ]"
                      v-model="StoreObj.new_details.contact_number"
                      :background-color="
                        StoreObj.existing_details.contact_number !==
                        StoreObj.new_details.contact_number
                          ? `grey lighten-3`
                          : ''
                      "
                    ></v-text-field>
                    <div
                      class="mt-n6 FontSizeSmall font-weight-bold font-weight-black red--text"
                      v-if="
                        StoreObj.existing_details.contact_number !==
                          StoreObj.new_details.contact_number
                      "
                    >
                      Changed
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="3">
                <!-- StoreObj.new_details['account_logo_url'] -->
                <v-card
                  outlined
                  height="200px"
                  width="300px"
                  align="center"
                  class="overflow-hidden mt-9 mx-3 pb-4"
                >
                  <v-img
                    v-if="
                      StoreObj.new_details.account_logo_url &&
                        StoreObj.new_details.account_logo_url !== ''
                    "
                    :src="StoreObj.new_details.account_logo_url"
                    max-width="200px"
                    class="mt-16"
                  ></v-img>
                  <div v-else class="mt-12">No Logo Uploaded</div>
                </v-card>
                <div
                  class="ml-5 FontSizeSmall font-weight-bold font-weight-black red--text"
                  v-if="
                    StoreObj.existing_details.account_logo_url !==
                      StoreObj.new_details.account_logo_url
                  "
                >
                  Changed
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0 pb-4 mr-2">
          <v-spacer></v-spacer>
          <v-btn
            small
            outlined
            class="borderRadius text-capitalize"
            color="secondary"
            @click="IssuerDetailsDialogEmit((Toggle = 1))"
            ><v-icon small class="mr-1">mdi-close</v-icon>Cancel
          </v-btn>
          <v-btn
            small
            dark
            :loading="loadingREJECTED"
            class="borderRadius text-capitalize"
            color="red"
            @click="approveRejectMethod('REJECTED')"
            ><v-icon small class="mr-1">mdi-cancel</v-icon>Reject
          </v-btn>
          <v-btn
            small
            :loading="loadingAPPROVED"
            class="borderRadius text-capitalize"
            color="primary"
            @click="approveRejectMethod('APPROVED')"
            ><v-icon small class="mr-1">mdi-check</v-icon>Approve
          </v-btn>
          <v-btn
            @click="modifyFields"
            color="primary"
            small
            :loading="loadingModify"
            class="text-capitalize borderRadius"
          >
            <v-icon small class="mr-1">mdi-pencil</v-icon>modify
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { GetAllSupportedCountry } from "@/mixins/GetAllSupportedCountry.js";
import { GetAllCountryCityStates } from "@/mixins/GetAllCountryCityStates.js";
import { ApproveOrRejectMofificationRequest } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    StoreObj: Object,
    dialogIssuerDetails: Boolean,
  },
  mixins: [GetAllCountryCityStates, GetAllSupportedCountry],
  components: {
    Snackbar,
  },
  data: () => ({
    height: 0,
    count: 0,
    search: null,
    current_view: "CREATE",
    modify_bool: false,
    SnackBarComponent: {},
    typeOfBusinessItems: [
      "issuer",
      "Limited Liability Partnership",
      "Sole Proprietorship",
      "Individual",
    ],
    stateList: [],
    citiesList: [],
    identificationTypeItems: [],
    loading: false,
    countryList: [
      {
        name: "India",
        dial_code: "+91",
        code: "IN",
      },
      {
        name: "United States",
        dial_code: "+1",
        code: "US",
      },
    ],
    GetAllSupportedCountryList: [],
    loadingAPPROVED: false,
    loadingREJECTED: false,
    new_details: {},
    existing_details: {},
    loadingModify: false,
  }),
  watch: {
    GetAllSupportedCountryList() {
      if (this.StoreObj.action == "VIEW") {
        this.identificationTypeItems = this.GetAllSupportedCountryList.filter(
          (item) => item.country_name == this.StoreObj.existing_details.country
        )[0].identification_types;
      }
    },
    dialogIssuerDetails(val) {
      if (val == true) {
        this.GetAllSupportedCountryMethod("ACTIVE");
        console.log("Check Object", this.StoreObj);
        for (let key in this.StoreObj.existing_details) {
          if (this.StoreObj.new_details[key] == undefined) {
            this.StoreObj.new_details[key] = this.StoreObj.existing_details[
              key
            ];
          }
        }
        this.$forceUpdate();
      }
    },
    "StoreObj.existing_details.country"(val) {
      if (
        this.dialogIssuerDetails == true &&
        val !== null &&
        val !== undefined &&
        val !== ""
      ) {
        if (this.StoreObj.action == "CREATE") {
          this.StoreObj.existing_details.state = "";
          this.StoreObj.existing_details.city = "";
          if (this.$refs.form !== undefined) {
            this.$refs.form.reset();
          }
        }
      }
    },
    "StoreObj.existing_details.state"(val) {
      if (
        this.dialogIssuerDetails == true &&
        val !== null &&
        val !== undefined &&
        val !== "" &&
        this.StoreObj.action == "CREATE"
      ) {
        if (this.StoreObj.action == "CREATE") {
          this.StoreObj.existing_details.city = "";
          if (this.$refs.form !== undefined) {
            this.$refs.form.reset();
          }
        }
        this.getAllCitiesMethod(this.StoreObj.existing_details.state);
      }
    },
  },
  mounted() {
    this.height = window.innerHeight - 150;
  },
  methods: {
    modifyFields() {
      this.loadingModify = true;
      this.modify_bool = true;
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "success",
        Top: true,
        SnackbarText: "You can now edit the form.",
      };
      setTimeout(() => {
        this.loadingModify = false;
      }, 500);
    },
    async approveRejectMethod(request_status) {
      try {
        this[`loading${request_status}`] = true;
        var inputParams = {
          modification_id: this.StoreObj.modification_id,
          request_status: request_status,
        };
        let result = await API.graphql(
          graphqlOperation(ApproveOrRejectMofificationRequest, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(
          result.data.ApproveOrRejectMofificationRequest
        );
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.IssuerDetailsDialogEmit((this.Toggle = 2));
        }
        this[`loading${request_status}`] = false;
      } catch (error) {
        this[`loading${request_status}`] = false;
      }
    },
    validateFormMethod() {
      if (this.$refs.form.validate()) {
        if (this.StoreObj.action == "CREATE") {
          this.current_view = "ENTER_PASSWORD";
        } else {
          this.approveRejectMethod();
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fields marked with asterisks(*) are mandatory",
        };
      }
    },
    IssuerDetailsDialogEmit(Toggle) {
      this.modify_bool = false;
      this.current_view = "CREATE";
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
