import { ListUsers } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllBMSUsers = {
  data() {
    return {
      overlay: false,
      GetAllBMSUserList: [],
    };
  },
  methods: {
    async GetAllBMSUserMethod() {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(ListUsers, {
            input: {},
          })
        );
        this.GetAllBMSUserList = JSON.parse(result.data.ListUsers).data.items;
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
      }
    },
  },
};
