<template>
  <v-dialog v-model="dialogAddHeaderFooter" persistent max-width="500px">
    <v-card class="overflow-hidden">
      <v-toolbar dark dense color="primary" class="elevation-0">
        <div v-if="StoreObj.position != undefined && StoreObj.type != undefined">Add {{ StoreObj.type.slice(0, 1) + StoreObj.type.slice(1).toLowerCase() }}</div>
        <v-spacer></v-spacer>
        <v-btn small outlined @click="clearFields()">Clear All</v-btn>
        <v-btn icon @click="dialogAddHeaderFooterEmit((Toggle = 1))">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="">
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="12">
            <v-card class="elevation-0">
              <div v-if="StoreObj.type != undefined" class="font-weight-bold mt-4">{{ `${StoreObj.type.slice(0, 1) + StoreObj.type.slice(1).toLowerCase()} Type` }}</div>
              <v-radio-group dense class="my-0 py-0" v-model="addHeaderFooter.content_type" row @change="changeContentTypeMethod()">
                <v-radio label="Text" value="TEXT"></v-radio>
                <v-radio label="ISSUER LOGO" value="BUSINESS_LOGO"></v-radio>
                <v-radio label="ISSUER NAME" value="BUSINESS_NAME"></v-radio>
              </v-radio-group>
            </v-card>
          </v-col>
        </v-row>
        <vue-editor v-if="addHeaderFooter.content_type == 'TEXT'" :editorToolbar="customToolbar" v-model="addHeaderFooter.content" placeholder="Add Content"></vue-editor>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small color="primary" @click="addHeaderFooterMethod">
          <v-icon small>mdi-plus</v-icon>
          Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueEditor,
  },
  props: {
    StoreObj: Object,
    GetAllMediaList: Array,
    dialogAddHeaderFooter: Boolean,
  },
  data: () => ({
    addHeaderFooter: {
      content: "",
      content_type: "",
    },
    selectImage: "",
    uploadedImage: false,
    typeItems: ["IMAGE", "TEXT"],
    customToolbar: [[{ header: [false, 1, 2, 3, 4, 5, 6] }], ["bold", "italic", "underline"], [{ color: [] }]],
  }),
  watch: {
    selectImage(val) {
      if (val != undefined && val != null && val != "") {
        this.addHeaderFooter.content = val;
      }
    },
    dialogAddHeaderFooter(val) {
      if (val == true) {
        if (this.StoreObj.content_present == true) {
          this.addHeaderFooter.content = this.StoreObj.content;
          this.addHeaderFooter.content_type = this.StoreObj.content_type;
          if (this.addHeaderFooter.content_type == "IMAGE") {
            this.selectImage = this.StoreObj.content;
          }
        }
      }
    },
  },
  methods: {
    changeContentTypeMethod() {
      if (this.addHeaderFooter.content_type == "TEXT") {
        this.selectImage = "";
        this.addHeaderFooter.content = "";
      } else if (this.addHeaderFooter.content_type == "BUSINESS_LOGO") {
        this.selectImage = "";
        this.addHeaderFooter.content = "ISSUER LOGO";
      } else if (this.addHeaderFooter.content_type == "BUSINESS_NAME") {
        this.selectImage = "";
        this.addHeaderFooter.content = "ISSUER NAME";
      }
    },
    checkImagePresent() {
      if (this.addHeaderFooter.content == null || this.addHeaderFooter.content == "" || this.addHeaderFooter.content == undefined) {
        this.handleUpload();
      }
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    handleClick(e) {
      this.selectedFile = e.target.files[0];
      var self = this;
      var fileReader = new FileReader();
      fileReader.readAsDataURL(self.selectedFile);
      fileReader.onload = async function(val) {
        self.addHeaderFooter.content = val.target.result;
      };
    },
    clearFields() {
      this.addHeaderFooter = {
        content: "",
        content_type: "",
      };
      this.dialogAddHeaderFooterEmit((this.Toggle = 2), this.addHeaderFooter);
    },
    addHeaderFooterMethod() {
      if (this.addHeaderFooter.content != undefined && this.addHeaderFooter.content != null) {
        let addHeaderFooter = { ...this.addHeaderFooter };
        this.dialogAddHeaderFooterEmit((this.Toggle = 2), addHeaderFooter);
      }
    },
    dialogAddHeaderFooterEmit(Toggle, content) {
      this.addHeaderFooter.content_type = "";
      this.addHeaderFooter.content = "";
      this.$emit("clicked", Toggle, content);
    },
  },
};
</script>
