var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Overlay',{attrs:{"overlay":_vm.overlay}}),_c('IssuerDetailsDialog',{attrs:{"StoreObj":_vm.StoreObj,"dialogIssuerDetails":_vm.dialogIssuerDetails},on:{"clicked":_vm.IssuerDetailsDialogEmit}}),_c('v-card',{staticClass:"elevation-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-toolbar',{staticClass:"elevation-0"},[_c('div',{staticClass:"PrimaryFontColor heading1"},[_vm._v("Approvals")])])],1),_c('v-toolbar',{staticClass:"elevation-0"},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-text-field',{staticClass:"FontSize field_label_size field_height borderRadius maxWidthLarge mt-9",attrs:{"dense":"","outlined":"","label":"Search Approvals"},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-magnify")])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-card-text',{staticClass:"pt-0"},[_c('v-data-table',{staticClass:"elevation-0 approvalTable",attrs:{"search":_vm.search,"dense":"","headers":_vm.GetAllApprovalsListHeaders,"items":_vm.GetAllApprovalsList,"fixed-header":true,"no-data-text":_vm.noDataText,"hide-default-footer":_vm.GetAllApprovalsList.length == 0,"footer-props":{
          'items-per-page-options': [100, 200, 300, 400, 500],
        },"items-per-page":100,"disable-sort":_vm.$vuetify.breakpoint.xs ? true : false},scopedSlots:_vm._u([{key:`item.sl_no`,fn:function({ item }){return [_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(_vm.GetAllApprovalsList.indexOf(item) + 1)+" ")])]}},{key:`item.old_issuer_name`,fn:function({ item }){return [_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.old_issuer_name)+" ")])]}},{key:`item.requested_on`,fn:function({ item }){return [_c('div',{staticClass:"FontSize",domProps:{"textContent":_vm._s(
              `${
                new Date(item.requested_on).toDateString() +
                ',' +
                ' ' +
                new Date(item.requested_on).toLocaleString().split(',')[1]
              }`
            )}})]}},{key:`item.requested_by`,fn:function({ item }){return [_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.requested_by)+" ")])]}},{key:`item.owner_name`,fn:function({ item }){return [_c('div',{staticClass:"FontSize"},[_vm._v(_vm._s(item.owner_name))])]}},{key:`item.request_status`,fn:function({ item }){return [_c('div',{staticClass:"FontSize",class:item.request_status == 'PENDING'
                ? 'BlueHeader'
                : item.request_status == 'REJECTED'
                ? 'RedHeader'
                : 'GreenHeader'},[_vm._v(" "+_vm._s(item.request_status)+" ")])]}},{key:`item.Action`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.checkItem(item, 'VIEW')}}},'v-icon',attrs,false),on),[_vm._v("mdi-eye")])]}}],null,true)},[_c('span',[_vm._v("View Details for "+_vm._s(item.old_issuer_name))])])]}}],null,true)})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }