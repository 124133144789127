import { ListCountries } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllSupportedCountry = {
  data() {
    return {
      overlay: false,
      GetAllSupportedCountryList: [],
    };
  },
  methods: {
    async GetAllSupportedCountryMethod(country_status) {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(ListCountries, {
            input: {
              country_status: country_status,
            },
          })
        );
        this.GetAllSupportedCountryList = JSON.parse(
          result.data.ListCountries
        ).data.items;
        console.warn(
          "GetAllSupportedCountryList",
          this.GetAllSupportedCountryList
        );
        this.overlay = false;
      } catch (error) {
        this.GetAllSupportedCountryList = [];
        this.noDataText = error.errors[0].message;
        this.overlay = false;
      }
    },
  },
};
