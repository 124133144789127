<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog
      persistent
      :max-width="documentView == false ? '400px' : '1000px'"
      v-model="dialogCreateCountry"
    >
      <v-card>
        <v-toolbar dark dense class="elevation-0" color="primary">
          <v-icon small class="mr-2">{{
            StoreObj.action == "CREATE" ? "mdi-plus" : "mdi-information"
          }}</v-icon
          ><span
            v-text="
              StoreObj.action == 'CREATE' ? 'Create Country' : 'Country Details'
            "
          ></span>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogCreateCountryEmit((Toggle = 1))"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-row no-gutters>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              :md="documentView == false ? 12 : 4"
            >
              <v-form ref="form">
                <v-select
                  dense
                  outlined
                  :readonly="StoreObj.action == 'EDIT'"
                  class="field_height field_label_size FontSize mt-5"
                  label="Country Name *"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="create_country.country_name"
                  :items="countryList"
                  item-text="name"
                  item-value="name"
                >
                  <template slot="selection" slot-scope="{ item }">
                    {{ item.name }}
                  </template>
                  <template slot="item" slot-scope="{ item }"
                    >{{ item.flag }} {{ item.name }}
                  </template>
                </v-select>
                <v-row no-gutters>
                  <v-col cols="12" xs="12" sm="12" md="4">
                    <v-text-field
                      readonly
                      dense
                      outlined
                      label="Currency"
                      class="field_height field_label_size FontSize mr-2"
                      :rules="[(v) => !!v || 'Required']"
                      v-model="create_country.currency_symbol"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="8">
                    <v-text-field
                      dense
                      outlined
                      label="Verification Fee *"
                      class="field_height field_label_size FontSize"
                      :rules="[
                        (v) =>
                          /^(?=[a-zA-Z0-9@._%+-]{0,30}$)[0-9]{0,64}$/.test(v) ||
                          'Invalid',
                      ]"
                      v-model="create_country.verification_fee"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-file-input
                  dense
                  outlined
                  prepend-icon=""
                  prepend-inner-icon="mdi-paperclip"
                  :clearable="false"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="supportedDocumentfile"
                  class="field_label_size FontSize"
                  label="Upload Welcome Email Documentation *"
                  accept="application/pdf"
                ></v-file-input>
              </v-form>
              <div class="font-weight-bold">
                Add Issuer Identification Types :
              </div>
              <v-row no-gutters class="mt-3">
                <v-col cols="12" xs="12" sm="12" md="10">
                  <v-form ref="formIdentification">
                    <v-text-field
                      dense
                      outlined
                      class="field_height field_label_size FontSize"
                      label="Issuer Identification Type *"
                      :rules="[
                        (v) => !!v || 'Please Add Issuer Identification Type',
                      ]"
                      v-model="identification_type"
                    ></v-text-field>
                  </v-form>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2">
                  <v-btn
                    small
                    class="ml-2 py-2"
                    color="primary"
                    @click="validateIdentificationAddMethod()"
                    ><v-icon>mdi-plus</v-icon></v-btn
                  >
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  v-if="identificationTypesArray.length != 0"
                >
                  <div class="font-weight-bold">
                    Issuer Identification Types :
                  </div>
                  <v-list-item
                    class="pa-0"
                    v-for="(item, idx) in identificationTypesArray"
                    :key="idx"
                    dense
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action class="pa-0 ma-0">
                      <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-on="on"
                            v-bind="attrs"
                            icon
                            @click="identificationTypesArray.splice(idx, 1)"
                          >
                            <v-icon color="red">mdi-close</v-icon>
                          </v-btn>
                        </template>
                        <span>Remove {{ item }}</span>
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" v-else align="center">
                  <div class="font-weight-bold">
                    No Identification Type Added Yet.
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="8" v-if="documentView == true">
              <v-row class="BorderPrimary mx-4 mt-5">
                <div class="xmediumFontSize py-2 pl-2">Selected Document</div>
                <iframe
                  :height="heightPDF"
                  :src="pdfsrc"
                  width="100%"
                  frameborder="0"
                ></iframe>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pt-0 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            small
            class="borderRadius"
            outlined
            color="secondary"
            @click="dialogCreateCountryEmit((Toggle = 1))"
            ><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn
          >
          <v-btn
            small
            class="mr-2 borderRadius"
            color="secondary"
            :loading="loading"
            @click="validateFormMethod()"
            ><v-icon small class="mr-1">mdi-check</v-icon
            ><span
              v-text="StoreObj.action == 'CREATE' ? 'Create' : 'Save'"
            ></span
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { CreateCountry, UpdateCountry } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
import { Country } from "country-state-city";
var AWS = require("aws-sdk");
export default {
  props: {
    dialogCreateCountry: Boolean,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    create_country: {
      country_name: "",
      currency_symbol: "",
      verification_fee: "",
    },
    pdfsrc: "",
    heightPDF: 0,
    loading: false,
    identification_type: "",
    SnackBarComponent: {},
    supportedDocumentfile: null,
    identificationTypesArray: [],
    countryList: [],
    documentView: false,
  }),
  watch: {
    "create_country.country_name"(val) {
      if (val != undefined && val != null && val != "") {
        this.create_country.currency_symbol = this.countryList.filter(
          (item) => item.name == this.create_country.country_name
        )[0].currency;
      }
    },
    supportedDocumentfile(val) {
      this.documentView = true;
      if (val != undefined && val != "" && val != null) {
        var fileReader = new FileReader();
        fileReader.readAsDataURL(val);
        var self = this;
        fileReader.onload = async function(val) {
          self.pdfsrc = val.target.result;
        };
      }
    },
    dialogCreateCountry(val) {
      if (val == true) {
        this.supportedDocumentfile = null;
        if (this.StoreObj.action == "EDIT") {
          this.create_country.country_name = this.StoreObj.country_name;
          (this.create_country.currency_symbol = this.StoreObj.currency_symbol),
            (this.create_country.verification_fee = this.StoreObj.verification_fee);
          this.identificationTypesArray = this.StoreObj.identification_types;
          this.pdfsrc = this.StoreObj.supported_document;
          this.documentView = true;
        } else {
          this.documentView = false;
        }
      }
    },
  },
  mounted() {
    this.countryList = Country.getAllCountries();
    this.heightPDF = window.innerHeight - 240;
  },
  methods: {
    async editCountryMethod(s3URL, s3_key) {
      var inputParams = {
        s3_key: s3_key,
        country_name: this.create_country.country_name,
        verification_fee: this.create_country.verification_fee,
        identification_types: this.identificationTypesArray,
        supported_document:
          this.supportedDocumentfile == null
            ? this.StoreObj.supported_document
            : s3URL,
      };
      if (s3_key == undefined) {
        inputParams.s3_key = this.StoreObj.s3_key;
      } else {
        inputParams.s3_key = s3_key;
      }
      try {
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(UpdateCountry, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.UpdateCountry);
        if (ResultObject.status == "Success") {
          this.$refs.form.reset();
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.dialogCreateCountryEmit((this.Toggle = 2));
        }
        this.loading = false;
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "error",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.loading = false;
      }
    },
    async uploadEmailSupportedFormatToS3() {
      this.loading = true;
      var self = this;
      var s3Bucket = new AWS.S3({
        region: "ap-south-1",
        accessKeyId: "AKIARIY6IPQHY3TVDGLL",
        secretAccessKey: "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",
      });
      var file_name = `${self.supportedDocumentfile.name}`;
      var fileReader = new FileReader();
      fileReader.readAsDataURL(self.supportedDocumentfile);
      fileReader.onload = async function() {
        var base64FileContent = fileReader.result.split(",");
        var buf = Buffer.from(base64FileContent[1], "base64");
        var param = {
          Bucket: "workhistory-mumbai",
          Key: `CountryDocs/${file_name}`,
          Body: buf,
          ContentType: self.supportedDocumentfile.type,
          ACL: "public-read-write",
        };
        await s3Bucket.putObject(param, function(err, data) {
          if (err) {
            console.log(err);
          } else if (data) {
            var s3URL = `https://${
              param.Bucket
            }.s3.ap-south-1.amazonaws.com/${encodeURI(param.Key)}`;
            if (self.StoreObj.action == "CREATE") {
              self.createCountryMethod(s3URL, param.Key);
            } else {
              self.editCountryMethod(s3URL, param.Key);
            }
          }
        });
      };
    },
    async createCountryMethod(s3URL, s3_key) {
      var inputParams = {
        s3_key: s3_key,
        user_id: this.$store.getters.get_current_user_details.user_id,
        country_name: this.create_country.country_name,
        currency_symbol: this.create_country.currency_symbol,
        verification_fee: this.create_country.verification_fee,
        country_code: this.countryList
          .filter((item) => item.name == this.create_country.country_name)[0]
          .phonecode.includes("+")
          ? this.countryList.filter(
              (item) => item.name == this.create_country.country_name
            )[0].phonecode
          : `+${
              this.countryList.filter(
                (item) => item.name == this.create_country.country_name
              )[0].phonecode
            }`,
        supported_document: s3URL,
        identification_types: this.identificationTypesArray,
      };
      try {
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(CreateCountry, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.CreateCountry);
        if (ResultObject.status == "Success") {
          this.$refs.form.reset();
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.dialogCreateCountryEmit((this.Toggle = 2));
        }
        this.loading = false;
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "error",
          Top: true,
          SnackbarText: ResultObject.Status_Message,
        };
        this.loading = false;
      }
    },
    validateFormMethod() {
      if (this.$refs.form.validate()) {
        if (this.StoreObj.action == "CREATE") {
          if (this.supportedDocumentfile != null) {
            this.uploadEmailSupportedFormatToS3();
          } else {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: "Please Select Supported Email Format Document",
            };
          }
        } else if (this.StoreObj.action == "EDIT") {
          if (this.supportedDocumentfile == null) {
            this.editCountryMethod();
          } else {
            this.uploadEmailSupportedFormatToS3();
          }
        }
      } else {
        if (this.StoreObj.action == "CREATE") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Fields marked with asterisks are mandatory",
          };
        } else {
          this.editCountryMethod();
        }
      }
    },
    validateIdentificationAddMethod() {
      if (this.$refs.formIdentification.validate()) {
        this.identificationTypesArray.push(this.identification_type);
        this.$refs.formIdentification.reset();
      }
    },
    dialogCreateCountryEmit(Toggle) {
      this.documentView = false;
      this.$forceUpdate();
      this.pdfsrc = "";
      this.identificationTypesArray = [];
      this.supportedDocumentfile = null;
      this.$emit("clicked", Toggle);
      if (this.$refs.form != undefined) {
        this.$refs.form.reset();
      }
      if (this.$refs.formIdentification != undefined) {
        this.$refs.formIdentification.reset();
      }
    },
  },
};
</script>
