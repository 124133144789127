<template>
  <div>
    <Overlay :overlay="overlay" />
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-card class="elevation-0">
      <v-card class="elevation-0">
        <v-row no-gutters>
          <v-col cols="12" md="5" sm="12">
            <v-toolbar class="elevation-0">
              <div class="PrimaryFontColor heading1">Arweave</div>
            </v-toolbar>
          </v-col>
        </v-row>
        <v-card-text class="py-0">
          <v-row no-gutters>
            <v-col cols="12" sm="12" md="12" xs="12" class="mt-4">
              <v-card class="elevation-0 BorderPrimary">
                <div class="pl-4 py-2 font-weight-bold">AR to INR :</div>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <v-row no-gutters>
                    <v-row no-gutters>
                      <v-col cols="12" xs="12" sm="12" md="2" align="center" class="font-weight-bold">
                        1 AR
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="1" align="center" class="font-weight-bold">
                        =
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="2" align="center">
                        <v-form ref="formDollar">
                          <v-text-field
                            dense
                            readonly
                            outlined
                            class="FontSize field_height field_label_size ml-2"
                            label="INR "
                            v-model="currency.rupee"
                            :rules="[(v) => /^(?=[a-zA-Z0-9@._%+-]{0,30}$)[0-9.]{0,64}$/.test(v) || 'Invalid']"
                          ></v-text-field>
                        </v-form>
                      </v-col>
                    </v-row>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="12" xs="12" class="mt-4">
              <v-card class="elevation-0 BorderPrimary">
                <div class="pl-4 py-2 font-weight-bold">Wallet Address :</div>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <v-row no-gutters>
                    <v-row no-gutters>
                      <v-col cols="12" xs="12" sm="12" md="2" align="center" class="font-weight-bold">
                        Wallet Address
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="1" align="center" class="font-weight-bold">
                        =
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="9" class="font-weight-bold">
                        {{ currency.public_key }}
                      </v-col>
                    </v-row>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="12" xs="12" class="mt-4">
              <v-card class="elevation-0 BorderPrimary">
                <div class="pl-4 py-2 font-weight-bold">Wallet Balance :</div>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <v-row no-gutters>
                    <v-row no-gutters>
                      <v-col cols="12" xs="12" sm="12" md="2" align="center" class="font-weight-bold">
                        Balance (In AR)
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="1" align="center" class="font-weight-bold">
                        =
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="2" align="center">
                        <v-form ref="formDollar">
                          <v-text-field dense readonly outlined class="FontSize field_height field_label_size ml-2" label="Balance (In AR)" v-model="currency.current_balance"></v-text-field>
                        </v-form>
                      </v-col>
                    </v-row>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card>
  </div>
</template>
<script>
import Snackbar from "@/components/Extras/Snackbar.vue";
import Overlay from "@/components/Extras/Overlay.vue";
import { GetAllDefaultCurrencyConfiguration } from "@/mixins/GetAllDefaultCurrencyConfiguration.js";
export default {
  components: {
    Overlay,
    Snackbar,
  },
  mixins: [GetAllDefaultCurrencyConfiguration],
  data: () => ({
    awunit_1: "",
    awunit_2: "",
    awvalue_1: "",
    awvalue_2: "",
    awunitItems: ["AR", "Winston"],
    crunit_1: "",
    crunit_2: "",
    crvalue_1: "",
    crvalue_2: "",
    crunitItems: ["Rupee", "Dollar"],
    currency: {
      ar: 1,
      winston: 1000000000000,
      dollar: null,
      rupee: null,
      public_key: "",
    },
    overlay: false,
    SnackBarComponent: {},
    loadingDollar: false,
    loadingRupee: false,
    winston: null,
    ar: null,
    dollar: null,
    rupee: null,
    select_ar_or_winston: "",
    select_ar_or_winstonItems: ["AR", "WINSTON"],
    entered_value: null,
    select_inr_or_usd: "",
    select_inr_or_usdItems: ["USD", "INR"],
    result: null,
  }),
  watch: {
    entered_value() {
      this.result = null;
    },
    select_ar_or_winston() {
      this.result = null;
    },
    select_inr_or_usd() {
      this.result = null;
    },
  },
  computed: {
    arresult() {
      let res = 0;
      if (
        this.awunit_1 != "" &&
        this.awunit_1 != undefined &&
        this.awunit_1 != null &&
        this.awvalue_1 != "" &&
        this.awvalue_1 != undefined &&
        this.awvalue_1 != null &&
        this.awunit_2 != "" &&
        this.awunit_2 != null &&
        this.awunit_2 != undefined
      ) {
        if (this.awunit_1 == "AR" && this.awunit_2 == "Winston") {
          res = parseInt(this.awvalue_1) * 10000000000;
        } else if (this.awunit_1 == "Winston" && this.awunit_2 == "AR") {
          res = parseInt(this.awvalue_1) / 10000000000;
        } else if (this.awunit_1 == this.awunit_2) {
          res = parseInt(this.awvalue_1);
        }
      }
      return res;
    },
    crresult() {
      let res = 0;
      if (
        this.crunit_1 != "" &&
        this.crunit_1 != undefined &&
        this.crunit_1 != null &&
        this.crvalue_1 != "" &&
        this.crvalue_1 != undefined &&
        this.crvalue_1 != null &&
        this.crunit_2 != "" &&
        this.crunit_2 != null &&
        this.crunit_2 != undefined
      ) {
        if (this.crunit_1 == "AR" && this.crunit_2 == "Winston") {
          res = parseInt(this.awvalue_1) * 10000000000;
        } else if (this.crunit_1 == "Winston" && this.crunit_2 == "AR") {
          res = parseInt(this.awvalue_1) / 10000000000;
        } else if (this.crunit_1 == this.crunit_2) {
          res = parseInt(this.awvalue_1);
        }
      }
      return res;
    },
  },
  mounted() {
    this.GetAllDefaultCurrencyConfigurationMethod();
  },
  methods: {
    calculateMethod() {
      if (this.select_ar_or_winston == "AR") {
        this.result = this.entered_value * (this.select_inr_or_usd == "USD" ? this.currency.dollar : this.currency.dollar * this.currency.rupee);
      } else if (this.select_ar_or_winston == "WINSTON") {
        this.result = (this.entered_value / 1000000000000) * (this.select_inr_or_usd == "USD" ? this.currency.dollar : this.currency.dollar * this.currency.rupee);
      }
    },
    validateCalculatorMethod() {
      if (this.$refs.formCalculator.validate()) {
        this.calculateMethod();
      }
    },
  },
};
</script>
