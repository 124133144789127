export const FormPDFTemplate = {
  data() {
    return {};
  },
  methods: {
    formPDFMethod() {
      this.template_html = "";
      if (this.templateItems.filter((item) => item.type == "HEADER" && item.content_present == true).length == 3) {
        let headerItems = this.templateItems.filter((item) => item.type == "HEADER" && item.content_present == true);
        console.warn("headerItems", headerItems);
        this.template_html = `
                  <div class="col-md-4 ql-align-left">
                    ${
                      headerItems.filter((item) => item.position == "LEFT")[0].content_type == "TEXT"
                        ? headerItems.filter((item) => item.position == "LEFT")[0].content
                        : headerItems.filter((item) => item.position == "LEFT")[0].content_type == "BUSINESS_LOGO"
                        ? `<h1>${headerItems.filter((item) => item.position == "LEFT")[0].content}</h1>`
                        : headerItems.filter((item) => item.position == "LEFT")[0].content_type == "BUSINESS_NAME"
                        ? `<h1>${headerItems.filter((item) => item.position == "LEFT")[0].content}</h1>`
                        : `<img src=${headerItems.filter((item) => item.position == "LEFT")[0].content} style="width:200px"/>`
                    }
                  </div>
                  <div class="col-md-4 ql-align-center">
                  ${
                    headerItems.filter((item) => item.position == "MIDDLE")[0].content_type == "TEXT"
                      ? headerItems.filter((item) => item.position == "MIDDLE")[0].content
                      : headerItems.filter((item) => item.position == "MIDDLE")[0].content_type == "BUSINESS_LOGO"
                      ? `<h1>${headerItems.filter((item) => item.position == "MIDDLE")[0].content}</h1>`
                      : headerItems.filter((item) => item.position == "MIDDLE")[0].content_type == "BUSINESS_NAME"
                      ? `<h1>${headerItems.filter((item) => item.position == "MIDDLE")[0].content}</h1>`
                      : `<img src=${headerItems.filter((item) => item.position == "MIDDLE")[0].content} style="width:200px"/>`
                  }
                  </div>
                  <div class="col-md-4 ql-align-right">
                  ${
                    headerItems.filter((item) => item.position == "RIGHT")[0].content_type == "TEXT"
                      ? headerItems.filter((item) => item.position == "RIGHT")[0].content
                      : headerItems.filter((item) => item.position == "RIGHT")[0].content_type == "BUSINESS_LOGO"
                      ? `<h1>${headerItems.filter((item) => item.position == "RIGHT")[0].content}</h1>`
                      : headerItems.filter((item) => item.position == "RIGHT")[0].content_type == "BUSINESS_NAME"
                      ? `<h1>${headerItems.filter((item) => item.position == "RIGHT")[0].content}</h1>`
                      : `<img src=${headerItems.filter((item) => item.position == "RIGHT")[0].content} style="width:200px"/>`
                  }
                  </div>
              `;
      }
      if (this.templateItems.filter((item) => item.type == "HEADER" && item.content_present == true).length == 2) {
        let headerItems = this.templateItems.filter((item) => item.type == "HEADER" && item.content_present == true);
        if (this.templateItems.filter((item) => item.type == "HEADER" && item.content_present == true && item.position != "MIDDLE").length == 2) {
          this.template_html = `
                  <div class="col-md-6 ql-align-left">
                    ${
                      headerItems.filter((item) => item.position == "LEFT")[0].content_type == "TEXT"
                        ? headerItems.filter((item) => item.position == "LEFT")[0].content
                        : headerItems.filter((item) => item.position == "LEFT")[0].content_type == "BUSINESS_LOGO"
                        ? `<h1>${headerItems.filter((item) => item.position == "LEFT")[0].content}</h1>`
                        : headerItems.filter((item) => item.position == "LEFT")[0].content_type == "BUSINESS_NAME"
                        ? `<h1>${headerItems.filter((item) => item.position == "LEFT")[0].content}</h1>`
                        : `<img src=${headerItems.filter((item) => item.position == "LEFT")[0].content} style="width:200px"/>`
                    }
                  </div>
                  <div class="col-md-6 ql-align-right">
                  ${
                    headerItems.filter((item) => item.position == "RIGHT")[0].content_type == "TEXT"
                      ? headerItems.filter((item) => item.position == "RIGHT")[0].content
                      : headerItems.filter((item) => item.position == "RIGHT")[0].content_type == "BUSINESS_LOGO"
                      ? `<h1>${headerItems.filter((item) => item.position == "RIGHT")[0].content}</h1>`
                      : headerItems.filter((item) => item.position == "RIGHT")[0].content_type == "BUSINESS_NAME"
                      ? `<h1>${headerItems.filter((item) => item.position == "RIGHT")[0].content}</h1>`
                      : `<img src=${headerItems.filter((item) => item.position == "RIGHT")[0].content} style="width:200px"/>`
                  }
                  </div>
              `;
        } else {
          this.template_html = `
                  <div class="col-md-4 ql-align-left">
                  ${
                    this.templateItems.filter((item) => item.type == "HEADER" && item.position == "LEFT")[0].content_present == true
                      ? this.templateItems.filter((item) => item.type == "HEADER" && item.position == "LEFT" && item.content_present == true)[0].content_type == "TEXT"
                        ? this.templateItems.filter((item) => item.type == "HEADER" && item.position == "LEFT" && item.content_present == true)[0].content
                        : this.templateItems.filter((item) => item.type == "HEADER" && item.position == "LEFT" && item.content_present == true)[0].content_type == "BUSINESS_LOGO"
                        ? `<h1>${this.templateItems.filter((item) => item.type == "HEADER" && item.position == "LEFT" && item.content_present == true)[0].content}</h1>`
                        : this.templateItems.filter((item) => item.type == "HEADER" && item.position == "LEFT" && item.content_present == true)[0].content_type == "BUSINESS_NAME"
                        ? `<h1>${this.templateItems.filter((item) => item.type == "HEADER" && item.position == "LEFT" && item.content_present == true)[0].content}</h1>`
                        : `<img src=${this.templateItems.filter((item) => item.type == "HEADER" && item.position == "LEFT" && item.content_present == true)[0].content} style="width:200px"/>`
                      : ""
                  }
                  </div>
                  <div class="col-md-4 ql-align-center">
                  ${
                    this.templateItems.filter((item) => item.type == "HEADER" && item.position == "MIDDLE")[0].content_present == true
                      ? this.templateItems.filter((item) => item.type == "HEADER" && item.position == "MIDDLE" && item.content_present == true)[0].content_type == "TEXT"
                        ? this.templateItems.filter((item) => item.type == "HEADER" && item.position == "MIDDLE" && item.content_present == true)[0].content
                        : this.templateItems.filter((item) => item.type == "HEADER" && item.position == "MIDDLE" && item.content_present == true)[0].content_type == "BUSINESS_LOGO"
                        ? `<h1>${this.templateItems.filter((item) => item.type == "HEADER" && item.position == "MIDDLE" && item.content_present == true)[0].content}</h1>`
                        : this.templateItems.filter((item) => item.type == "HEADER" && item.position == "MIDDLE" && item.content_present == true)[0].content_type == "BUSINESS_NAME"
                        ? `<h1>${this.templateItems.filter((item) => item.type == "HEADER" && item.position == "MIDDLE" && item.content_present == true)[0].content}</h1>`
                        : `<img src=${this.templateItems.filter((item) => item.type == "HEADER" && item.position == "MIDDLE" && item.content_present == true)[0].content} style="width:200px"/>`
                      : ""
                  }
                  </div>
                  <div class="col-md-4 ql-align-right">
                  ${
                    this.templateItems.filter((item) => item.type == "HEADER" && item.position == "RIGHT")[0].content_present == true
                      ? this.templateItems.filter((item) => item.type == "HEADER" && item.position == "RIGHT" && item.content_present == true)[0].content_type == "TEXT"
                        ? this.templateItems.filter((item) => item.type == "HEADER" && item.position == "RIGHT" && item.content_present == true)[0].content
                        : this.templateItems.filter((item) => item.type == "HEADER" && item.position == "RIGHT" && item.content_present == true)[0].content_type == "BUSINESS_LOGO"
                        ? `<h1>${this.templateItems.filter((item) => item.type == "HEADER" && item.position == "RIGHT" && item.content_present == true)[0].content}</h1>`
                        : this.templateItems.filter((item) => item.type == "HEADER" && item.position == "RIGHT" && item.content_present == true)[0].content_type == "BUSINESS_NAME"
                        ? `<h1>${this.templateItems.filter((item) => item.type == "HEADER" && item.position == "RIGHT" && item.content_present == true)[0].content}</h1>`
                        : `<img src=${this.templateItems.filter((item) => item.type == "HEADER" && item.position == "RIGHT" && item.content_present == true)[0].content} style="width:200px"/>`
                      : ""
                  }
                  </div>
             `;
        }
      }
      if (this.templateItems.filter((item) => item.type == "HEADER" && item.content_present == true).length == 1) {
        let headerItems = this.templateItems.filter((item) => item.type == "HEADER" && item.content_present == true);
        if (headerItems.filter((item) => item.position == "LEFT").length == 1) {
          headerItems = this.templateItems.filter((item) => item.position == "LEFT");
          this.template_html = `
                  <div class="col-md-12 ql-align-left">
                  ${
                    headerItems.filter((item) => item.position == "LEFT")[0].content_present == true
                      ? headerItems.filter((item) => item.position == "LEFT")[0].content_type == "TEXT"
                        ? headerItems.filter((item) => item.position == "LEFT" && item.content_present == true)[0].content
                        : headerItems.filter((item) => item.position == "LEFT" && item.content_present == true)[0].content_type == "BUSINESS_LOGO"
                        ? `<h1>${headerItems.filter((item) => item.position == "LEFT")[0].content}</h1>`
                        : headerItems.filter((item) => item.position == "LEFT" && item.content_present == true)[0].content_type == "BUSINESS_NAME"
                        ? `<h1>${headerItems.filter((item) => item.position == "LEFT")[0].content}</h1>`
                        : `<img src=${headerItems.filter((item) => item.position == "LEFT")[0].content} style="width:200px"/>`
                      : ""
                  }
                  </div>
               `;
        }
        if (headerItems.filter((item) => item.position == "MIDDLE").length == 1) {
          this.template_html = `
                  <div class="col-md-12 ql-align-center">
                  ${
                    headerItems.filter((item) => item.position == "MIDDLE")[0].content_present == true
                      ? headerItems.filter((item) => item.position == "MIDDLE")[0].content_type == "TEXT"
                        ? headerItems.filter((item) => item.position == "MIDDLE" && item.content_present == true)[0].content
                        : headerItems.filter((item) => item.position == "MIDDLE" && item.content_present == true)[0].content_type == "BUSINESS_LOGO"
                        ? `<h1>${headerItems.filter((item) => item.position == "MIDDLE")[0].content}</h1>`
                        : headerItems.filter((item) => item.position == "MIDDLE" && item.content_present == true)[0].content_type == "BUSINESS_NAME"
                        ? `<h1>${headerItems.filter((item) => item.position == "MIDDLE")[0].content}</h1>`
                        : `<img src=${headerItems.filter((item) => item.position == "MIDDLE")[0].content} style="width:200px"/>`
                      : ""
                  }
                  </div>
                `;
        }
        if (headerItems.filter((item) => item.position == "RIGHT").length == 1) {
          this.template_html = `
                  <div class="col-md-12 ql-align-right">
                  ${
                    headerItems.filter((item) => item.position == "RIGHT")[0].content_present == true
                      ? headerItems.filter((item) => item.position == "RIGHT")[0].content_type == "TEXT"
                        ? headerItems.filter((item) => item.position == "RIGHT" && item.content_present == true)[0].content
                        : headerItems.filter((item) => item.position == "RIGHT" && item.content_present == true)[0].content_type == "BUSINESS_LOGO"
                        ? `<h1>${headerItems.filter((item) => item.position == "RIGHT")[0].content}</h1>`
                        : headerItems.filter((item) => item.position == "RIGHT" && item.content_present == true)[0].content_type == "BUSINESS_NAME"
                        ? `<h1>${headerItems.filter((item) => item.position == "RIGHT")[0].content}</h1>`
                        : `<img src=${headerItems.filter((item) => item.position == "RIGHT")[0].content} style="width:200px"/>`
                      : ""
                  }
                  </div>
               `;
        }
      }
      // ===================================================================================================================================
      // ===================================================================================================================================
      // =================================================IMAGE TOP============================================================================
      let image_html_top = "";
      if (this.templateItems.filter((item) => item.type == "IMAGE" && item.position == "TOP" && item.content_present == true).length != 0) {
        image_html_top = `
               <div class="col-md-12 ql-align-center">
                  ${this.templateItems.filter((item) => item.type == "IMAGE" && item.position == "TOP" && item.content_present == true)[0].content}
               </div>
             `;
      }
      this.template_html = this.template_html.concat(image_html_top);
      // ===================================================================================================================================
      // ===================================================================================================================================
      // =================================================MAIN CONTENT============================================================================
      let main_content_html = "";
      if (this.templateItems.filter((item) => item.type == "MAIN CONTENT" && item.content_present == true).length != 0) {
        main_content_html = `
               <div class="col-md-12 ${
                 this.templateItems.filter((item) => item.type == "MAIN CONTENT" && item.content_present == true)[0].content.includes("ql-align-right") ||
                 this.templateItems.filter((item) => item.type == "MAIN CONTENT" && item.content_present == true)[0].content.includes("ql-align-center") ||
                 this.templateItems.filter((item) => item.type == "MAIN CONTENT" && item.content_present == true)[0].content.includes("ql-align-justify")
                   ? ""
                   : "ql-align-left"
               }">
                  ${this.templateItems.filter((item) => item.type == "MAIN CONTENT" && item.content_present == true)[0].content}
               </div>
              `;
      }
      this.template_html = this.template_html.concat(main_content_html);
      // ===================================================================================================================================
      // ===================================================================================================================================
      // =================================================IMAGE BOTTOM============================================================================
      let image_html_bottom = "";
      if (this.templateItems.filter((item) => item.type == "IMAGE" && item.position == "BOTTOM" && item.content_present == true).length != 0) {
        image_html_bottom = `
               <div class="col-md-12 ql-align-center">
                  ${this.templateItems.filter((item) => item.type == "IMAGE" && item.position == "BOTTOM" && item.content_present == true)[0].content}
               </div>
             `;
      }
      this.template_html = this.template_html.concat(image_html_bottom);
      // ===================================================================================================================================
      // ===================================================================================================================================
      // =================================================SIGNATURE=========================================================================
      let signature_html = "";
      if (this.templateItems.filter((item) => item.type == "SIGNATURE")[0].content_present == true) {
        let previewhtml = `<h1>SIGNATURE</h1>`;
        let signatureObject = this.templateItems.filter((item) => item.type == "SIGNATURE")[0];
        if (signatureObject.signature_Settings.configure_text_before_signature == true) {
          previewhtml = signatureObject.signature_Settings.text_content_before_signature.concat(previewhtml);
        }
        if (signatureObject.signature_Settings.configure_text_after_signature == true) {
          previewhtml = previewhtml.concat(signatureObject.signature_Settings.text_content_after_signature);
        }
        this.templateItems.filter((item) => item.type == "SIGNATURE")[0].content = previewhtml;
      }
      if (this.templateItems.filter((item) => item.type == "SIGNATURE" && item.position == "LEFT" && item.content_present == true).length != 0) {
        signature_html = `
               <div class="col-md-12 ql-align-left">
                  ${this.templateItems.filter((item) => item.type == "SIGNATURE" && item.position == "LEFT" && item.content_present == true)[0].content}
               </div>
              `;
      }
      if (this.templateItems.filter((item) => item.type == "SIGNATURE" && item.position == "RIGHT" && item.content_present == true).length != 0) {
        signature_html = `
               <div class="col-md-12 ql-align-right">
                  ${this.templateItems.filter((item) => item.type == "SIGNATURE" && item.position == "RIGHT" && item.content_present == true)[0].content}
               </div>
             `;
      }
      this.template_html = this.template_html.concat(signature_html);
      // ===================================================================================================================================
      // ===================================================================================================================================
      // =================================================FOOTER============================================================================
      let footer_html = "";
      if (this.templateItems.filter((item) => item.type == "FOOTER" && item.content_present == true).length == 3) {
        let footerItems = this.templateItems.filter((item) => item.type == "FOOTER" && item.content_present == true);
        footer_html = `
                  <div class="col-md-4 ql-align-left">
                    ${
                      footerItems.filter((item) => item.position == "LEFT")[0].content_type == "TEXT"
                        ? footerItems.filter((item) => item.position == "LEFT")[0].content
                        : footerItems.filter((item) => item.position == "LEFT")[0].content_type == "BUSINESS_LOGO"
                        ? `<h1>${footerItems.filter((item) => item.position == "LEFT")[0].content}</h1>`
                        : footerItems.filter((item) => item.position == "LEFT")[0].content_type == "BUSINESS_NAME"
                        ? `<h1>${footerItems.filter((item) => item.position == "LEFT")[0].content}</h1>`
                        : `<img src=${footerItems.filter((item) => item.position == "LEFT")[0].content} style="width:200px"/>`
                    }
                  </div>
                  <div class="col-md-4 ql-align-center">
                  ${
                    footerItems.filter((item) => item.position == "MIDDLE")[0].content_type == "TEXT"
                      ? footerItems.filter((item) => item.position == "MIDDLE")[0].content
                      : footerItems.filter((item) => item.position == "MIDDLE")[0].content_type == "BUSINESS_LOGO"
                      ? `<h1>${footerItems.filter((item) => item.position == "MIDDLE")[0].content}</h1>`
                      : footerItems.filter((item) => item.position == "MIDDLE")[0].content_type == "BUSINESS_NAME"
                      ? `<h1>${footerItems.filter((item) => item.position == "MIDDLE")[0].content}</h1>`
                      : `<img src=${footerItems.filter((item) => item.position == "MIDDLE")[0].content} style="width:200px"/>`
                  }
                  </div>
                  <div class="col-md-4 ql-align-right">
                  ${
                    footerItems.filter((item) => item.position == "RIGHT")[0].content_type == "TEXT"
                      ? footerItems.filter((item) => item.position == "RIGHT")[0].content
                      : footerItems.filter((item) => item.position == "RIGHT")[0].content_type == "BUSINESS_LOGO"
                      ? `<h1>${footerItems.filter((item) => item.position == "RIGHT")[0].content}</h1>`
                      : footerItems.filter((item) => item.position == "RIGHT")[0].content_type == "BUSINESS_NAME"
                      ? `<h1>${footerItems.filter((item) => item.position == "RIGHT")[0].content}</h1>`
                      : `<img src=${footerItems.filter((item) => item.position == "RIGHT")[0].content} style="width:200px"/>`
                  }
                  </div>
              `;
      }
      // =====================================================@@@@@@@@@@@@@@222222222222222@@@@@@@@@@@@@@@@=========================================================================================================
      if (this.templateItems.filter((item) => item.type == "FOOTER" && item.content_present == true).length == 2) {
        let footerItems = this.templateItems.filter((item) => item.type == "FOOTER" && item.content_present == true);
        if (this.templateItems.filter((item) => item.type == "FOOTER" && item.content_present == true && item.position != "MIDDLE").length == 2) {
          footer_html = `
                  <div class="col-md-6 ql-align-left padding-top">
                  ${
                    footerItems.filter((item) => item.position == "LEFT")[0].content_present == true
                      ? footerItems.filter((item) => item.position == "LEFT")[0].content_type == "TEXT"
                        ? footerItems.filter((item) => item.position == "LEFT")[0].content
                        : footerItems.filter((item) => item.position == "LEFT")[0].content_type == "BUSINESS_LOGO"
                        ? `<h1>${footerItems.filter((item) => item.position == "LEFT" && item.content_present == true)[0].content}</h1>`
                        : footerItems.filter((item) => item.position == "LEFT")[0].content_type == "BUSINESS_NAME"
                        ? `<h1>${footerItems.filter((item) => item.position == "LEFT" && item.content_present == true)[0].content}</h1>`
                        : `<img src=${footerItems.filter((item) => item.position == "LEFT")[0].content} style="width:200px"/>`
                      : ""
                  }
                  </div>
                  <div class="col-md-6 ql-align-right padding-top">
                  ${
                    footerItems.filter((item) => item.position == "RIGHT")[0].content_present == true
                      ? footerItems.filter((item) => item.position == "RIGHT")[0].content_type == "TEXT"
                        ? footerItems.filter((item) => item.position == "RIGHT")[0].content
                        : footerItems.filter((item) => item.position == "RIGHT")[0].content_type == "BUSINESS_LOGO"
                        ? `<h1>${footerItems.filter((item) => item.position == "RIGHT" && item.content_present == true)[0].content}</h1>`
                        : footerItems.filter((item) => item.position == "RIGHT")[0].content_type == "BUSINESS_NAME"
                        ? `<h1>${footerItems.filter((item) => item.position == "RIGHT" && item.content_present == true)[0].content}</h1>`
                        : `<img src=${footerItems.filter((item) => item.position == "RIGHT")[0].content} style="width:200px"/>`
                      : ""
                  }
                  </div>
              `;
        } else {
          footer_html = `
                  <div class="col-md-4 ql-align-left padding-top">
                    ${
                      this.templateItems.filter((item) => item.position == "LEFT" && item.type == "FOOTER")[0].content_present == true
                        ? this.templateItems.filter((item) => item.position == "LEFT" && item.type == "FOOTER")[0].content_type == "TEXT"
                          ? this.templateItems.filter((item) => item.position == "LEFT" && item.type == "FOOTER")[0].content
                          : this.templateItems.filter((item) => item.position == "LEFT" && item.type == "FOOTER")[0].content_type == "BUSINESS_LOGO"
                          ? `<h1>${this.templateItems.filter((item) => item.type == "FOOTER" && item.position == "LEFT" && item.content_present == true)[0].content}</h1>`
                          : this.templateItems.filter((item) => item.position == "LEFT" && item.type == "FOOTER")[0].content_type == "BUSINESS_NAME"
                          ? `<h1>${this.templateItems.filter((item) => item.type == "FOOTER" && item.position == "LEFT" && item.content_present == true)[0].content}</h1>`
                          : `<img src=${this.templateItems.filter((item) => item.position == "LEFT" && item.type == "FOOTER")[0].content} style="width:200px"/>`
                        : ""
                    }
                  </div>
                  <div class="col-md-4 ql-align-center padding-top">
                    ${
                      this.templateItems.filter((item) => item.position == "MIDDLE" && item.type == "FOOTER")[0].content_present == true
                        ? this.templateItems.filter((item) => item.position == "MIDDLE" && item.type == "FOOTER")[0].content_type == "TEXT"
                          ? this.templateItems.filter((item) => item.position == "MIDDLE" && item.type == "FOOTER")[0].content
                          : this.templateItems.filter((item) => item.position == "MIDDLE" && item.type == "FOOTER")[0].content_type == "BUSINESS_LOGO"
                          ? `<h1>${this.templateItems.filter((item) => item.type == "FOOTER" && item.position == "LEFT" && item.content_present == true)[0].content}</h1>`
                          : this.templateItems.filter((item) => item.position == "MIDDLE" && item.type == "FOOTER")[0].content_type == "BUSINESS_NAME"
                          ? `<h1>${this.templateItems.filter((item) => item.type == "FOOTER" && item.position == "LEFT" && item.content_present == true)[0].content}</h1>`
                          : `<img src=${this.templateItems.filter((item) => item.position == "MIDDLE" && item.type == "FOOTER")[0].content} style="width:200px"/>`
                        : ""
                    }
                  </div>
                  <div class="col-md-4 ql-align-right padding-top">
                  ${
                    this.templateItems.filter((item) => item.position == "RIGHT" && item.type == "FOOTER")[0].content_present == true
                      ? this.templateItems.filter((item) => item.position == "RIGHT" && item.type == "FOOTER")[0].content_type == "TEXT"
                        ? this.templateItems.filter((item) => item.position == "RIGHT" && item.type == "FOOTER")[0].content
                        : this.templateItems.filter((item) => item.position == "RIGHT" && item.type == "FOOTER")[0].content_type == "BUSINESS_LOGO"
                        ? `<h1>${this.templateItems.filter((item) => item.type == "FOOTER" && item.position == "LEFT" && item.content_present == true)[0].content}</h1>`
                        : this.templateItems.filter((item) => item.position == "RIGHT" && item.type == "FOOTER")[0].content_type == "BUSINESS_NAME"
                        ? `<h1>${this.templateItems.filter((item) => item.type == "FOOTER" && item.position == "LEFT" && item.content_present == true)[0].content}</h1>`
                        : `<img src=${this.templateItems.filter((item) => item.position == "RIGHT" && item.type == "FOOTER")[0].content} style="width:200px"/>`
                      : ""
                  }
                  </div>
              `;
        }
      }
      if (this.templateItems.filter((item) => item.type == "FOOTER" && item.content_present == true).length == 1) {
        let footerItems = this.templateItems.filter((item) => item.type == "FOOTER" && item.content_present == true);
        if (footerItems.filter((item) => item.position == "LEFT").length == 1) {
          footer_html = `
                  <div class="col-md-12 ql-align-left padding-top">
                  ${
                    footerItems.filter((item) => item.position == "LEFT")[0].content_present == true
                      ? footerItems.filter((item) => item.position == "LEFT")[0].content_type == "TEXT"
                        ? footerItems.filter((item) => item.position == "LEFT")[0].content
                        : footerItems.filter((item) => item.position == "LEFT")[0].content_type == "BUSINESS_LOGO"
                        ? `<h1>${this.templateItems.filter((item) => item.type == "FOOTER" && item.position == "LEFT" && item.content_present == true)[0].content}</h1>`
                        : footerItems.filter((item) => item.position == "LEFT")[0].content_type == "BUSINESS_NAME"
                        ? `<h1>${this.templateItems.filter((item) => item.type == "FOOTER" && item.position == "LEFT" && item.content_present == true)[0].content}</h1>`
                        : `<img src=${footerItems.filter((item) => item.position == "LEFT")[0].content} style="width:200px"/>`
                      : ""
                  }
                  </div>
               `;
        }
      }
      if (this.templateItems.filter((item) => item.type == "FOOTER" && item.content_present == true).length == 1) {
        let footerItems = this.templateItems.filter((item) => item.type == "FOOTER" && item.content_present == true);
        if (footerItems.filter((item) => item.position == "LEFT").length == 1) {
          footer_html = `
                  <div class="col-md-12 ql-align-left">
                  ${
                    footerItems.filter((item) => item.position == "LEFT")[0].content_present == true
                      ? footerItems.filter((item) => item.position == "LEFT")[0].content_type == "TEXT"
                        ? footerItems.filter((item) => item.position == "LEFT")[0].content
                        : footerItems.filter((item) => item.position == "LEFT")[0].content_type == "BUSINESS_LOGO"
                        ? `<h1>${footerItems.filter((item) => item.position == "LEFT")[0].content}</h1>`
                        : footerItems.filter((item) => item.position == "LEFT")[0].content_type == "BUSINESS_NAME"
                        ? `<h1>${footerItems.filter((item) => item.position == "LEFT")[0].content}</h1>`
                        : `<img src=${footerItems.filter((item) => item.position == "LEFT")[0].content} style="width:200px"/>`
                      : ""
                  }
                  </div>
               `;
        }
        if (footerItems.filter((item) => item.position == "MIDDLE").length == 1) {
          footer_html = `
                  <div class="col-md-12 ql-align-center">
                  ${
                    footerItems.filter((item) => item.position == "MIDDLE")[0].content_present == true
                      ? footerItems.filter((item) => item.position == "MIDDLE")[0].content_type == "TEXT"
                        ? footerItems.filter((item) => item.position == "MIDDLE")[0].content
                        : footerItems.filter((item) => item.position == "MIDDLE")[0].content_type == "BUSINESS_LOGO"
                        ? `<h1>${footerItems.filter((item) => item.position == "MIDDLE")[0].content}</h1>`
                        : footerItems.filter((item) => item.position == "MIDDLE")[0].content_type == "BUSINESS_NAME"
                        ? `<h1>${footerItems.filter((item) => item.position == "MIDDLE")[0].content}</h1>`
                        : `<img src=${footerItems.filter((item) => item.position == "MIDDLE")[0].content} style="width:200px"/>`
                      : ""
                  }
                  </div>
                `;
        }
        if (footerItems.filter((item) => item.position == "RIGHT").length == 1) {
          footer_html = `
                  <div class="col-md-12 ql-align-right">
                  ${
                    footerItems.filter((item) => item.position == "RIGHT")[0].content_present == true
                      ? footerItems.filter((item) => item.position == "RIGHT")[0].content_type == "TEXT"
                        ? footerItems.filter((item) => item.position == "RIGHT")[0].content
                        : footerItems.filter((item) => item.position == "RIGHT")[0].content_type == "BUSINESS_LOGO"
                        ? `<h1>${footerItems.filter((item) => item.position == "RIGHT")[0].content}</h1>`
                        : footerItems.filter((item) => item.position == "RIGHT")[0].content_type == "BUSINESS_NAME"
                        ? `<h1>${footerItems.filter((item) => item.position == "RIGHT")[0].content}</h1>`
                        : `<img src=${footerItems.filter((item) => item.position == "RIGHT")[0].content} style="width:200px"/>`
                      : ""
                  }
                  </div>
                `;
        }
      }
      this.template_html = this.template_html.concat(footer_html);
      this.template_html = `<html>
                  <head>
                    <style>
                    .margin-top:{
                      margin-top:150px
                    }
                      .ql-align-center {
                        text-align: center;
                      }
                      .ql-align-right {
                        text-align: right;
                      }
                      .ql-align-justify {
                        text-align: justify;
                      }
                    </style>
                  </head>
                  <body>
                  <div class="row" style="background-color:${this.templateItems.filter((item) => item.type == "BACKGROUND")[0].content};height:1060px;margin:20px;border:${
        this.templateItems.filter((item) => item.type == "BORDER")[0].content
      };border-spacing: 15px;">
                  ${this.template_html}
                  </div>
                  </body>
                  </html>`;
      // =================================================END============================================================================
      if (this.$route.name == "Dashboard") {
        this.StoreObj.template_html = this.template_html;
        this.deciderMethod(this.StoreObj, this.StoreObj.action);
      }
    },
  },
};
