import { Country, State, City } from "country-state-city";
export const GetAllCountryCityStates = {
  data() {
    return {
      stateList: [],
      citiesList: [],
      countryList: [],
    };
  },
  methods: {
    getAllCountriesMethod() {
      this.countryList = Country.getAllCountries();
      console.warn("Countries:", this.countryList);
      for (let i = 0; i < this.countryList.length; i++) {
        if (!this.countryList[i].phonecode.includes("+")) {
          this.countryList[i].phonecode = `+${this.countryList[i].phonecode}`;
        }
      }
    },
    getAllStatesMethod(country_name) {
      console.warn("country_name", country_name);
      this.stateList = State.getStatesOfCountry(
        this.countryList.filter((country) => country.name == country_name)[0]
          .isoCode
      );
      console.warn("States:", this.stateList);
    },
    getAllCitiesMethod(state_name) {
      let current_state = this.stateList.filter(
        (state) => state.name == state_name
      )[0];
      this.citiesList = City.getCitiesOfState(
        current_state.countryCode,
        current_state.isoCode
      );
      console.warn("Cities:", this.citiesList);
    },
  },
};
