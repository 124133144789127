<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog persistent max-width="400px" v-model="dialogSavePDFWarning">
      <v-card>
        <v-toolbar dark color="primary" dense class="elevation-0">
          <div>Save Template</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogSavePDFWarningEmit((Toggle = 1))"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="pb-0 mt-4">
          <v-form ref="form">
            <v-text-field
              dense
              outlined
              class="FontSize"
              :readonly="
                TemplateStoreObj != undefined &&
                  TemplateStoreObj.action == 'EDIT'
              "
              label="Template Name"
              v-model="template_name"
              :rules="[(v) => !!v || 'Required']"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="py-0 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            small
            class="borderRadius fontStyle text-capitalize"
            color="secondary"
            outlined
            @click="dialogSavePDFWarningEmit((Toggle = 1))"
            ><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn
          >
          <v-btn
            small
            class="borderRadius fontStyle text-capitalize"
            :loading="loading"
            color="secondary"
            @click="validateMethod()"
            ><v-icon small class="mr-1">mdi-check</v-icon>Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Snackbar from "@/components/Extras/Snackbar.vue";
import { API, graphqlOperation } from "aws-amplify";
import { CreateTemplate, UpdateTemplate } from "@/graphql/mutations.js";
export default {
  props: {
    StoreObj: Object,
    TemplateStoreObj: Object,
    dialogSavePDFWarning: Boolean,
    template_html: String,
    templateItems: Array,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    Toggle: 0,
    loading: false,
    template_name: "",
    SnackBarComponent: {},
  }),
  watch: {
    dialogSavePDFWarning(val) {
      if (val == true) {
        console.warn("TemplateStoreObj", this.TemplateStoreObj);
        if (this.TemplateStoreObj.action == "EDIT") {
          this.template_name = this.TemplateStoreObj.template_name;
        }
      }
    },
  },
  methods: {
    validateMethod() {
      if (this.$refs.form.validate()) {
        if (
          this.TemplateStoreObj.action == "CREATE" ||
          this.TemplateStoreObj.action == "COPY"
        ) {
          this.createTemplatesMethod();
        } else {
          this.updateTemplatesMethod();
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter Template Name",
        };
      }
    },
    async createTemplatesMethod() {
      try {
        console.warn("templateItems", this.templateItems);
        var inputParams = {
          template_name: this.template_name,
          settings: JSON.stringify(this.templateItems),
          template_html: `
          <html>
            <head>
              <style>
              .margin-top:{
                margin-top:150px
              }
              .ql-align-left {
                  text-align: left;
                }
                .ql-align-center {
                  text-align: center;
                }
                .ql-align-right {
                  text-align: right;
                }
                .ql-align-justify {
                  text-align: justify;
                }
              </style>
            </head>
            <body>
              <div>${this.template_html}</div>
            </body>
          </html>`,
        };
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(CreateTemplate, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.CreateTemplate);
        if (ResultObject.status == "Success") {
          this.dialogSavePDFWarningEmit((this.Toggle = 2), ResultObject);
        }
        this.loading = false;
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.loading = false;
      }
    },
    async updateTemplatesMethod() {
      try {
        var inputParams = {
          template_id: this.TemplateStoreObj.template_id,
          template_name: this.template_name,
          settings: JSON.stringify(this.templateItems),
          template_html: `
          <html>
            <head>
              <style>
              .margin-top:{
                margin-top:150px
              }
                .ql-align-center {
                  text-align: center;
                }
                .ql-align-right {
                  text-align: right;
                }
                .ql-align-justify {
                  text-align: justify;
                }
              </style>
            </head>
            <body>
               <div>${this.template_html}</div>
            </body>
          </html>`,
        };
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(UpdateTemplate, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.UpdateTemplate);
        if (ResultObject.status == "Success") {
          this.dialogSavePDFWarningEmit((this.Toggle = 2), ResultObject);
        }
        this.loading = false;
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.loading = false;
      }
    },
    dialogSavePDFWarningEmit(Toggle, ResultObject) {
      if (this.$refs.form != undefined) {
        this.$refs.form.reset();
      }
      this.$emit("clicked", Toggle, ResultObject);
    },
  },
};
</script>
