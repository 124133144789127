<template>
  <v-dialog v-model="dialogAddImage" persistent :max-width="'900px'">
    <v-card class="overflow-hidden">
      <v-toolbar dark dense color="primary" class="elevation-0">
        <div v-if="StoreObj.position != undefined && StoreObj.type != undefined">Add {{ StoreObj.type.slice(0, 1) + StoreObj.type.slice(1).toLowerCase() }}</div>
        <v-spacer></v-spacer>
        <v-btn small dark outlined @click="clearImage((Toggle = 1))"> Clear All </v-btn>
        <v-btn icon @click="dialogAddImageEmit((Toggle = 1))">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row class="mt-4">
          <v-col cols="12" xs="12" sm="12" md="6">
            <v-card class="elevation-0">
              <v-radio-group label="Content Type" dense class="my-0 py-0" v-model="addImage.content_type" row @change="changeContentTypeMethod()">
                <v-radio label="ISSUER LOGO" value="BUSINESS_LOGO"></v-radio>
              </v-radio-group>
              <div class="font-weight-bold">Configure Text Before/ After Image</div>
              <v-checkbox
                dense
                class="px-0 mx-0 pb-0 mb-0"
                label="Configure Text Before Image"
                v-model="text_settings.configure_text_before_image"
                @change="configureTextMethod('BEFOREIMAGE')"
              ></v-checkbox>
              <vue-editor
                :disabled="text_settings.configure_text_before_image == false"
                class="pb-4 editorHeightSM"
                :editorToolbar="customToolbar"
                v-model="text_settings.text_content_before_image"
                placeholder="Add Content"
              ></vue-editor>
              <v-checkbox
                dense
                class="px-0 mx-0 pt-12 pb-0 mb-0"
                label="Configure Text After Signature"
                v-model="text_settings.configure_text_after_image"
                @change="configureTextMethod('AFTERIMAGE')"
              ></v-checkbox>
              <vue-editor
                :disabled="text_settings.configure_text_after_image == false"
                class="pb-4 editorHeightSM"
                :editorToolbar="customToolbar"
                v-model="text_settings.text_content_after_image"
                placeholder="Add Content"
              ></vue-editor>
            </v-card>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6">
            <v-card outlined height="100%">
              <v-card-title class="py-0">Preview</v-card-title>
              <v-card-text v-if="addImage.content != null && addImage.content != ''" class="py-0">
                <div class="mt-1" v-html="previewhtml"></div>
              </v-card-text>
              <v-card v-else align="center" class="overflow-hidden elevation-0">
                <v-card-text>
                  <h3 style="padding-top: 90px">Please Select An Image To Preview</h3>
                </v-card-text>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small color="primary" @click="addImageMethod">
          <v-icon small>mdi-plus</v-icon>
          Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { VueEditor } from "vue2-editor";
export default {
  props: {
    StoreObj: Object,
    dialogAddImage: Boolean,
    GetAllMediaList: Array,
  },
  components: {
    VueEditor,
  },
  data: () => ({
    Toggle: 0,
    addImage: {
      content_type: "BUSINESS_LOGO",
      content: "ISSUER LOGO",
      position: "",
    },
    customToolbar: [[{ header: [false, 1, 2, 3, 4, 5, 6] }], ["bold", "italic", "underline"], [{ color: [] }]],
    textPositionItems: [
      { text: "BEFOREIMAGE", value: "BEFOREIMAGE" },
      { text: "After Image", value: "AFTERIMAGE" },
    ],
    text_settings: {
      text_content: "",
      configure_text_before_image: false,
      text_content_before_image: "",
      configure_text_after_image: false,
      text_content_after_image: "",
    },
    preview: false,
    previewhtml: "<h1>ISSUER LOGO</h1>",
    ImageHeaders: [
      { text: "Text Position", value: "content_position" },
      { text: "Action", value: "Action" },
    ],
    selectImage: "",
  }),
  watch: {
    "text_settings.text_content_before_image"(val) {
      if (val == "") {
        this.text_settings.configure_text_before_image = false;
      }
      this.changeSettingsMethod("BEFOREIMAGE");
    },
    "text_settings.text_content_after_image"(val) {
      if (val == "") {
        this.text_settings.configure_text_after_image = false;
      }
      this.changeSettingsMethod("AFTERIMAGE");
    },
    dialogAddImage(val) {
      if (val == true) {
        this.text_settings.text_content = `<h1>ISSUER LOGO</h1>`;
        this.addImage.content_type = "BUSINESS_LOGO";
        this.addImage.content = `<h1>ISSUER LOGO</h1>`;
        this.previewhtml = `<h1>ISSUER LOGO</h1>`;
        this.addImage.position = this.StoreObj.position;
        if (this.StoreObj.content_present == true && this.StoreObj.position == "TOP") {
          this.previewhtml = this.StoreObj.content;
          this.text_settings = this.StoreObj.text_settings;
        }
        if (this.StoreObj.content_present == true && this.StoreObj.position == "BOTTOM") {
          this.previewhtml = this.StoreObj.content;
          this.text_settings = this.StoreObj.text_settings;
        }
      }
    },
  },
  methods: {
    clearImage() {
      this.text_settings = {
        text_content: "<h1>ISSUER LOGO</h1>",
        configure_text_before_image: false,
        text_content_before_image: "",
        configure_text_after_image: false,
        text_content_after_image: "",
      };
      let addImage = {};
      addImage.position = this.StoreObj.position;
      addImage.text_settings = this.text_settings;
      addImage.content = "";
      addImage.content_type = "IMAGE";
      this.dialogAddImageEmit((this.Toggle = 2), addImage);
    },
    configureTextMethod(Position) {
      switch (Position) {
        case "BEFOREIMAGE":
          this.text_settings.text_content_before_image = "";
          break;
        case "AFTERIMAGE":
          this.text_settings.text_content_after_image = "";
          break;
      }
    },
    changeSettingsMethod() {
      this.previewhtml = "<h1>ISSUER LOGO</h1>";
      if (this.text_settings.configure_text_before_image == true) {
        this.previewhtml = this.text_settings.text_content_before_image.concat(this.previewhtml);
      }
      if (this.text_settings.configure_text_after_image == true) {
        this.previewhtml = this.previewhtml.concat(this.text_settings.text_content_after_image);
      }
      this.addImage.content = this.previewhtml;
    },
    addImageMethod() {
      if (this.addImage.content != undefined && this.addImage.content_type != null && this.addImage.content_type != undefined && this.addImage.content_type != null) {
        let addImage = { ...this.addImage };
        addImage.text_settings = this.text_settings;
        addImage.content = this.previewhtml;
        this.dialogAddImageEmit((this.Toggle = 2), addImage);
      }
    },
    dialogAddImageEmit(Toggle, addImage) {
      this.addImage = {
        content_type: "",
        content: "",
        position: "",
      };
      this.text_settings = {
        text_content: "<h1>ISSUER LOGO</h1>",
        configure_text_before_image: false,
        text_content_before_image: "",
        configure_text_after_image: false,
        text_content_after_image: "",
      };
      this.preview = false;
      this.previewhtml = "";
      this.$emit("clicked", Toggle, addImage);
    },
  },
};
</script>
