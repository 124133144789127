/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const CreateUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput) {
    CreateUser(input: $input)
  }
`;
export const CreateIssuer = /* GraphQL */ `
  mutation CreateIssuer($input: createIssuerInput) {
    CreateIssuer(input: $input)
  }
`;
export const UpdateIssuer = /* GraphQL */ `
  mutation UpdateIssuer($input: UpdateIssuerInput) {
    UpdateIssuer(input: $input)
  }
`;
export const CreateCountry = /* GraphQL */ `
  mutation CreateCountry($input: CreateCountryInput) {
    CreateCountry(input: $input)
  }
`;
export const UpdateCountry = /* GraphQL */ `
  mutation UpdateCountry($input: UpdateCountryInput) {
    UpdateCountry(input: $input)
  }
`;
export const UpdateIssuerDetails = /* GraphQL */ `
  mutation UpdateIssuerDetails($input: UpdateIssuerDetailsInput) {
    UpdateIssuerDetails(input: $input)
  }
`;
export const ActivateOrDeactivateUser = /* GraphQL */ `
  mutation ActivateOrDeactivateUser($input: ActivateOrDeactivateUserInput) {
    ActivateOrDeactivateUser(input: $input)
  }
`;
export const CreateTemplate = /* GraphQL */ `
  mutation CreateTemplate($input: CreateTemplateInput) {
    CreateTemplate(input: $input)
  }
`;
export const UpdateTemplate = /* GraphQL */ `
  mutation UpdateTemplate($input: UpdateTemplateInput) {
    UpdateTemplate(input: $input)
  }
`;
export const DeleteTemplate = /* GraphQL */ `
  mutation DeleteTemplate($input: DeleteTemplateInput) {
    DeleteTemplate(input: $input)
  }
`;
export const CreateCategory = /* GraphQL */ `
  mutation CreateCategory($input: CreateCategoryInput) {
    CreateCategory(input: $input)
  }
`;
export const ActivateOrDeactivateCategory = /* GraphQL */ `
  mutation ActivateOrDeactivateCategory(
    $input: ActivateOrDeactivateCategoryInput
  ) {
    ActivateOrDeactivateCategory(input: $input)
  }
`;
export const ActivateOrDeactivateTemplate = /* GraphQL */ `
  mutation ActivateOrDeactivateTemplate(
    $input: ActivateOrDeactivateTemplateInput
  ) {
    ActivateOrDeactivateTemplate(input: $input)
  }
`;
export const ApproveOrRejectMofificationRequest = /* GraphQL */ `
  mutation ApproveOrRejectMofificationRequest(
    $input: ApproveOrRejectMofificationRequestInput
  ) {
    ApproveOrRejectMofificationRequest(input: $input)
  }
`;
export const CreateIssuerType = /* GraphQL */ `
  mutation CreateIssuerType($input: CreateIssuerTypeInput) {
    CreateIssuerType(input: $input)
  }
`;
export const ActivateOrDeactiveIssuerType = /* GraphQL */ `
  mutation ActivateOrDeactiveIssuerType(
    $input: ActivateOrDeactiveIssuerTypeInput
  ) {
    ActivateOrDeactiveIssuerType(input: $input)
  }
`;
export const CreatePartner = /* GraphQL */ `
  mutation CreatePartner($input: CreatePartnerInput) {
    CreatePartner(input: $input)
  }
`;
export const ActivateOrDeactivePartner = /* GraphQL */ `
  mutation ActivateOrDeactivePartner($input: ActivateOrDeactivePartnerInput) {
    ActivateOrDeactivePartner(input: $input)
  }
`;
export const UpdatePartner = /* GraphQL */ `
  mutation UpdatePartner($input: UpdatePartnerInput) {
    UpdatePartner(input: $input)
  }
`;
export const ApproveOrRejectPendingIssuer = /* GraphQL */ `
  mutation ApproveOrRejectPendingIssuer(
    $input: ApproveOrRejectPendingIssuerInput
  ) {
    ApproveOrRejectPendingIssuer(input: $input)
  }
`;
export const configurePlatformAndPartnerFee = /* GraphQL */ `
  mutation ConfigurePlatformAndPartnerFee(
    $input: configurePlatformAndPartnerFeeInput
  ) {
    configurePlatformAndPartnerFee(input: $input)
  }
`;
export const ActivateOrDeactivateCountry = /* GraphQL */ `
  mutation ActivateOrDeactivateCountry(
    $input: ActivateOrDeactivateCountryInput
  ) {
    ActivateOrDeactivateCountry(input: $input)
  }
`;
export const RLTakeActionOnPendingOrganization = /* GraphQL */ `
  mutation RLTakeActionOnPendingOrganization(
    $input: RLTakeActionOnPendingOrganizationInput
  ) {
    RLTakeActionOnPendingOrganization(input: $input)
  }
`;
export const VerifyOrganisation = /* GraphQL */ `
  mutation VerifyOrganisation($input: VerifyOrganisationInput!) {
    VerifyOrganisation(input: $input)
  }
`;
export const ApproveRejectModifyRequestRL = /* GraphQL */ `
  mutation ApproveRejectModifyRequestRL(
    $input: ApproveRejectModifyRequestRLInput
  ) {
    ApproveRejectModifyRequestRL(input: $input)
  }
`;
