import { ListMoficationRequests } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllApprovals = {
  data() {
    return {
      overlay: false,
      GetAllApprovalsList: [],
      noDataText: "",
    };
  },
  methods: {
    async GetAllApprovalsMethod() {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(ListMoficationRequests, {
            input: {},
          })
        );
        this.GetAllApprovalsList = JSON.parse(
          result.data.ListMoficationRequests
        ).data.items;
        console.warn("GetAllApprovalsList:", this.GetAllApprovalsList);

        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        this.noDataText = error.errors[0].message;
        this.GetAllApprovalsList = [];
      }
    },
  },
};
