<template>
  <v-dialog v-model="dialogAddSignature" persistent :max-width="'900px'">
    <v-card class="overflow-hidden">
      <v-toolbar dark dense color="primary" class="elevation-0">
        <div v-if="StoreObj.position != undefined && StoreObj.type != undefined">Add {{ StoreObj.type.slice(0, 1) + StoreObj.type.slice(1).toLowerCase() }}</div>
        <v-spacer></v-spacer>
        <v-btn small dark outlined @click="clearSignature((Toggle = 1))"> Clear All </v-btn>
        <v-btn icon @click="dialogAddSignatureEmit((Toggle = 1))">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="6">
            <v-card class="elevation-0">
              <div class="font-weight-bold mt-4"></div>
              <v-radio-group label="Alignment" dense class="my-0 py-0" v-model="addSignature.position" row>
                <v-radio label="Left" value="LEFT"></v-radio>
                <v-radio label="Right" value="RIGHT"></v-radio>
              </v-radio-group>
              <div class="font-weight-bold">Configure Text Before/ After Signature</div>
              <v-checkbox
                dense
                class="px-0 mx-0 pb-0 mb-0"
                label="Configure Text Before Signature"
                v-model="text_settings.configure_text_before_signature"
                @change="configureTextMethod('BEFORESIGNATURE')"
              ></v-checkbox>
              <vue-editor
                class="editorHeightSM"
                :editorToolbar="customToolbar"
                :disabled="text_settings.configure_text_before_signature == false"
                v-model="text_settings.text_content_before_signature"
                placeholder="Add Content"
              ></vue-editor>
              <v-checkbox
                dense
                class="px-0 mx-0 pt-12 pb-0 mb-0"
                label="Configure Text After Signature"
                v-model="text_settings.configure_text_after_signature"
                @change="configureTextMethod('AFTERSIGNATURE')"
              ></v-checkbox>
              <vue-editor
                class="editorHeightSM"
                :editorToolbar="customToolbar"
                :disabled="text_settings.configure_text_after_signature == false"
                v-model="text_settings.text_content_after_signature"
                placeholder="Add Content"
              ></vue-editor>
            </v-card>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6">
            <v-card outlined class="mt-2">
              <v-card-title class="py-0">Preview</v-card-title>
              <v-card-text class="py-0">
                <div class="mt-1" v-html="previewhtml"></div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small color="primary" @click="addSignatureMethod">
          <v-icon small>mdi-plus</v-icon>
          Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { VueEditor } from "vue2-editor";
export default {
  props: {
    StoreObj: Object,
    dialogAddSignature: Boolean,
    GetAllMediaList: Array,
  },
  components: {
    VueEditor,
  },
  data: () => ({
    Toggle: 0,
    addSignature: {
      content_type: "SIGNATURE",
      content: "",
      position: "",
    },
    customToolbar: [[{ header: [false, 1, 2, 3, 4, 5, 6] }], ["bold", "italic", "underline"], [{ color: [] }]],
    textPositionItems: ["BEFORE SIGNATURE", "AFTER SIGNATURE"],
    text_settings: {
      text_content: "<h1>SIGNATURE</h1>",
      configure_text_before_signature: false,
      text_content_before_signature: "",
      configure_text_after_signature: false,
      text_content_after_signature: "",
      text_position: "",
    },
    preview: false,
    positionItems: ["LEFT", "RIGHT"],
    previewhtml: "<h1>SIGNATURE</h1>",
    signatureSettings: [],
  }),
  watch: {
    "text_settings.text_content_before_signature"(val) {
      if(val==""){
        this.text_settings.configure_text_before_signature = false
      }
      this.changeSettingsMethod("BEFORESIGNATURE");
    },
    "text_settings.text_content_after_signature"(val) {
       if(val==""){
        this.text_settings.configure_text_after_signature = false
      }
      this.changeSettingsMethod("AFTERSIGNATURE");
    },
    dialogAddSignature(val) {
      if (val == true) {
        this.addSignature.position = "LEFT";
        this.previewhtml = "<h1>SIGNATURE</h1>";
        this.text_settings.text_content = "<h1>SIGNATURE</h1>";
        this.addSignature.position = "LEFT";
        this.addSignature.content_type = "SIGNATURE";
        if (this.StoreObj.content_present == true) {
          this.addSignature.position = this.StoreObj.position;
          this.text_settings = this.StoreObj.signature_Settings;
          this.previewhtml = this.StoreObj.content;
          console.warn("Check StoreObj", this.StoreObj);
        }
      }
    },
  },
  methods: {
    configureTextMethod(Position) {
      switch (Position) {
        case "BEFORESIGNATURE":
          this.text_settings.text_content_before_signature = "";
          break;
        case "AFTERSIGNATURE":
          this.text_settings.text_content_after_signature = "";
          break;
      }
    },
    clearSignature() {
      this.text_settings = {
        text_content: "",
        position: "",
        configure_text_before_signature: false,
        text_content_before_signature: "",
        configure_text_after_signature: false,
        text_content_after_signature: "",
      };
      let addSignature = {};
      addSignature.position = "";
      addSignature.signatureSettings = this.text_settings;
      addSignature.content = "";
      addSignature.content_type = "SIGNATURE";
      this.dialogAddSignatureEmit((this.Toggle = 2), this.addSignature);
    },
    changeSettingsMethod() {
      this.previewhtml = "<h1>SIGNATURE</h1>";
      if (this.text_settings.configure_text_before_signature == true) {
        this.previewhtml = this.text_settings.text_content_before_signature.concat(this.previewhtml);
      }
      if (this.text_settings.configure_text_after_signature == true) {
        this.previewhtml = this.previewhtml.concat(this.text_settings.text_content_after_signature);
      }
    },
    addSignatureMethod() {
      if (this.addSignature.content != undefined && this.addSignature.content_type != null && this.addSignature.content_type != undefined && this.addSignature.content_type != null) {
        let addSignature = { ...this.addSignature };
        addSignature.signatureSettings = this.text_settings;
        addSignature.content = this.previewhtml;
        this.addSignature.content_type = "";
        this.addSignature.content = "";
        this.addSignature.position = "";
        this.dialogAddSignatureEmit((this.Toggle = 2), addSignature);
      }
    },
    dialogAddSignatureEmit(Toggle, addSignature) {
      this.addSignature.content = "";
      this.$emit("clicked", Toggle, addSignature);
    },
  },
};
</script>
