<template>
  <v-dialog v-model="dialogAddText" persistent max-width="800px">
    <v-card class="overflow-hidden">
      <v-toolbar dark dense color="primary" class="elevation-0">
        <div v-if="StoreObj.position != undefined && StoreObj.type != undefined">Add {{ StoreObj.type.slice(0, 1) + StoreObj.type.slice(1).toLowerCase() }}</div>
        <v-spacer></v-spacer>
        <v-btn small outlined @click="clearFields()">Clear All</v-btn>
        <v-btn icon @click="dialogAddTextEmit((Toggle = 1))">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <div v-if="StoreObj.type != undefined" class="font-weight-bold mt-4">Content Type</div>
        <v-form ref="formActiveFields" @submit.prevent>
          <v-radio-group label="" :rules="[(v) => !!v || 'Required']" dense class="my-0 py-0" v-model="addText.content_type" row>
            <v-radio label="Active Field" value="ACTIVE_FIELD"></v-radio>
            <v-radio label="Issuer Name" value="BUSINESS_NAME"></v-radio>
          </v-radio-group>
          <v-card-actions class="pa-0">
            <v-text-field
              dense
              outlined
              class="FontSize field_height field_label_size mr-2"
              :rules="[(v) => !!v || 'Required', (v) => (v && v.length < 51) || 'Invalid']"
              label="Active Data Field"
              @keydown.enter="validateActiveFieldsMethod()"
              v-model="add_active_field.field_name"
            ></v-text-field>
            <v-btn small class="borderRadius mt-n6" color="primary" @click="validateActiveFieldsMethod()">
              <v-icon small>mdi-plus</v-icon>
              Add</v-btn
            >
          </v-card-actions>
        </v-form>
        <vue-editor :editorToolbar="customToolbar" v-model="addText.content" placeholder="Add Content" class="mt-4"></vue-editor>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small color="primary" @click="addTextMethod">
          <v-icon small>mdi-plus</v-icon>
          Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueEditor,
  },
  props: {
    StoreObj: Object,
    dialogAddText: Boolean,
  },
  data: () => ({
    addText: {
      content: "",
      content_type: "ACTIVE_FIELD",
    },
    add_active_field: {
      field_name: "",
    },
    uploadedImage: false,
    typeItems: ["IMAGE", "TEXT"],
    customToolbar: [[{ header: [false, 1, 2, 3, 4, 5, 6] }], ["bold", "italic", "underline"], [{ color: [] }], [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }]],
    allActiveFields: [],
  }),
  watch: {
    "addText.content_type"(val) {
      if (val == "BUSINESS_NAME") {
        this.add_active_field.field_name = `ISSUER NAME`;
      } else {
        this.add_active_field.field_name = "";
        this.$refs.formActiveFields.resetValidation();
      }
    },
    dialogAddText(val) {
      if (val == true) {
        this.addText.content_type = "ACTIVE_FIELD";
        if (this.StoreObj.content_present == true) {
          this.addText.content = this.StoreObj.content;
          this.addText.content_type = this.StoreObj.content_type;
          this.calculateActiveFieldsMethod();
        }
      }
    },
  },
  methods: {
    calculateActiveFieldsMethod() {
      this.allActiveFields = [];
      for (let i = 0; i < this.addText.content.split("}").length; i++) {
        if (this.addText.content.split("}")[i].includes("{")) {
          if (this.addText.content.split("}")[i].split("{")[1] != "") {
            this.allActiveFields.push(this.addText.content.split("}")[i].split("{")[1]);
          }
        }
      }
      this.$emit("activefieldsUpdate", this.allActiveFields);
    },
    validateActiveFieldsMethod() {
      if (this.$refs.formActiveFields.validate()) {
        if (this.addText.content_type == "ACTIVE_FIELD") {
          if (this.addText.content != "" && this.addText.content != undefined && this.addText.content != null) {
            let fullString = `${this.addText.content} ${this.add_active_field.field_name}`;
            this.addText.content = fullString.replace(`</p> ${this.add_active_field.field_name}`, "${" + `${this.add_active_field.field_name}}</p>`);
            this.$refs.formActiveFields.reset();
          } else {
            this.addText.content = "${" + `${this.add_active_field.field_name}` + "}";
            this.$refs.formActiveFields.reset();
          }
          this.calculateActiveFieldsMethod();
        } else {
          if (this.addText.content != "" && this.addText.content != undefined && this.addText.content != null) {
            let fullString = `${this.addText.content} ${this.add_active_field.field_name}`;
            this.addText.content = fullString.replace(`</p> ${this.add_active_field.field_name}`, `<b>${this.add_active_field.field_name}</b>`);
            this.$refs.formActiveFields.reset();
          }else{
            this.addText.content = `<b>${this.add_active_field.field_name}</b>`;
            this.$refs.formActiveFields.reset();
          }
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter Field Name",
        };
      }
    },
    checkImagePresent() {
      if (this.addText.content == null || this.addText.content == "" || this.addText.content == undefined) {
        this.handleUpload();
      }
    },
    clearFields() {
      this.addText = {
        content: "",
        content_type: "",
      };
      this.dialogAddTextEmit((this.Toggle = 2), this.addText);
      this.$refs.formActiveFields.resetValidation();
    },
    addTextMethod() {
      if (this.addText.content != undefined && this.addText.content != null) {
        let addText = { ...this.addText };
        this.dialogAddTextEmit((this.Toggle = 2), addText);
      }
    },
    dialogAddTextEmit(Toggle, content) {
      this.addText.content_type = "";
      this.addText.content = "";
      this.$emit("clicked", Toggle, content);
    },
  },
};
</script>
