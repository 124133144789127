<template>
  <div>
    <Overlay :overlay="overlay" />
    <IssuerDetailsDialog
      :StoreObj="StoreObj"
      :dialogIssuerDetails="dialogIssuerDetails"
      @clicked="IssuerDetailsDialogEmit"
    />
    <v-card class="elevation-0">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <div class="PrimaryFontColor heading1">Approvals</div>
          </v-toolbar>
        </v-col>

        <v-toolbar class="elevation-0">
          <v-spacer></v-spacer>
          <v-col cols="12" xs="12" sm="12" md="6">
            <v-text-field
              dense
              outlined
              label="Search Approvals"
              class="FontSize field_label_size field_height borderRadius maxWidthLarge mt-9"
              v-model="search"
            >
              <template v-slot:prepend-inner>
                <v-icon small class="mr-1">mdi-magnify</v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-toolbar>
      </v-row>
      <v-card-text class="pt-0">
        <v-data-table
          :search="search"
          dense
          class="elevation-0 approvalTable"
          :headers="GetAllApprovalsListHeaders"
          :items="GetAllApprovalsList"
          :fixed-header="true"
          :no-data-text="noDataText"
          :hide-default-footer="GetAllApprovalsList.length == 0"
          :footer-props="{
            'items-per-page-options': [100, 200, 300, 400, 500],
          }"
          :items-per-page="100"
          :disable-sort="$vuetify.breakpoint.xs ? true : false"
        >
          <template v-slot:[`item.sl_no`]="{ item }">
            <div class="FontSize">
              {{ GetAllApprovalsList.indexOf(item) + 1 }}
            </div>
          </template>
          <template v-slot:[`item.old_issuer_name`]="{ item }">
            <div class="FontSize">
              {{ item.old_issuer_name }}
            </div>
          </template>
          <template v-slot:[`item.requested_on`]="{ item }">
            <div
              class="FontSize"
              v-text="
                `${
                  new Date(item.requested_on).toDateString() +
                  ',' +
                  ' ' +
                  new Date(item.requested_on).toLocaleString().split(',')[1]
                }`
              "
            ></div>
          </template>
          <template v-slot:[`item.requested_by`]="{ item }">
            <div class="FontSize">
              {{ item.requested_by }}
            </div>
          </template>
          <template v-slot:[`item.owner_name`]="{ item }">
            <div class="FontSize">{{ item.owner_name }}</div>
          </template>
          <template v-slot:[`item.request_status`]="{ item }">
            <div
              class="FontSize"
              :class="
                item.request_status == 'PENDING'
                  ? 'BlueHeader'
                  : item.request_status == 'REJECTED'
                  ? 'RedHeader'
                  : 'GreenHeader'
              "
            >
              {{ item.request_status }}
            </div>
          </template>
          <template v-slot:[`item.Action`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="checkItem(item, 'VIEW')"
                  >mdi-eye</v-icon
                >
              </template>
              <span>View Details for {{ item.old_issuer_name }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import IssuerDetailsDialog from "@/components/Approvals/Dialogs/IssuerDetailsDialog.vue";
import { GetAllApprovals } from "@/mixins/GetAllApprovals.js";
export default {
  mixins: [GetAllApprovals],
  components: {
    Overlay,
    IssuerDetailsDialog,
  },
  data: () => ({
    search: "",
    overlay: false,
    GetAllApprovalsListHeaders: [
      { text: "SL NO.", value: "sl_no" },
      { text: "Issuer Name", value: "old_issuer_name" },
      { text: "Created On", value: "requested_on" },
      { text: "Owner Name", value: "owner_name" },
      { text: "Owner Email ID", value: "requested_by" },
      { text: "Status", value: "request_status" },
      { text: "Action", value: "Action" },
    ],
    StoreObj: {},
    dialogIssuerDetails: false,
  }),
  mounted() {
    this.GetAllApprovalsMethod();
  },
  methods: {
    IssuerDetailsDialogEmit(Toggle) {
      this.dialogIssuerDetails = false;
      if (Toggle == 2) {
        this.GetAllApprovalsMethod();
      }
    },
    checkItem(item, action) {
      this.StoreObj = item;
      this.StoreObj.action = action;
      if (action == "VIEW") {
        this.dialogIssuerDetails = true;
      }
    },
  },
};
</script>
<style></style>
