<template>
  <div>
    <Overlay :overlay="overlay" />
    <CreateCountryDialog
      :StoreObj="StoreObj"
      :dialogCreateCountry="dialogCreateCountry"
      @clicked="dialogCreateCountryEmit"
    />
    <ActivateDeactivateCountryDialog
      :StoreObj="StoreObj"
      :dialogActivateInactivateCountry="dialogActivateInactivateCountry"
      @clicked="dialogActivateInactivateCountryEmit"
    />
    <v-card class="elevation-0">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <div class="PrimaryFontColor heading1">Supported Countries</div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <v-spacer></v-spacer>
            <v-select
              dense
              outlined
              label="Status"
              class="FontSize field_label_size field_height borderRadius mt-9 mr-3 maxWidthSmall"
              v-model="country_status"
              :items="country_statusItems"
            ></v-select>
            <v-tooltip bottom color="#474747">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  v-on="on"
                  v-bind="attrs"
                  color="secondary"
                  v-on:click="dialogCreateCountry = true"
                  @click="StoreObj.action = 'CREATE'"
                  class="borderRadius mt-2"
                  ><v-icon small class="mr-1">mdi-plus</v-icon>Create</v-btn
                >
              </template>
              <span>Create Country</span>
            </v-tooltip>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text>
        <v-data-table
          dense
          class="elevation-0"
          :no-data-text="noDataText"
          :headers="SupportedCountryListHeaders"
          :items="GetAllSupportedCountryList"
          :disable-sort="$vuetify.breakpoint.xs ? true : false"
          :hide-default-footer="GetAllSupportedCountryList.length == 0"
        >
          <template v-slot:[`item.sl_no`]="{ item }">
            <div class="FontSize">
              {{ GetAllSupportedCountryList.indexOf(item) + 1 }}
            </div>
          </template>
          <template v-slot:[`item.country_name`]="{ item }">
            <div class="FontSize blackAndBoldFont">
              {{ item.country_name }}
            </div>
          </template>
          <template v-slot:[`item.country_code`]="{ item }">
            <div class="FontSize">
              {{ item.country_code }}
            </div>
          </template>
          <template v-slot:[`item.currency_symbol`]="{ item }">
            <div class="FontSize">
              {{
                item.currency_symbol == undefined ? "-" : item.currency_symbol
              }}
            </div>
          </template>
          <template v-slot:[`item.verification_fee`]="{ item }">
            <div class="FontSize">
              {{ item.verification_fee }}
            </div>
          </template>
          <template v-slot:[`item.country_created_on`]="{ item }">
            <div class="FontSize">
              {{ new Date(item.country_created_on).toLocaleString() }}
            </div>
          </template>
          <template v-slot:[`item.country_status`]="{ item }">
            <div
              class="FontSize font-weight-bold"
              :class="
                item.country_status == 'ACTIVE' ? 'GreenHeader' : 'RedHeader'
              "
            >
              {{ item.country_status }}
            </div>
          </template>
          <template v-slot:[`item.Action`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  color="primary"
                  dark
                  @click="checkItem(item, 'EDIT')"
                  class="mr-3"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-eye
                </v-icon>
              </template>
              <span>View Country</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.country_status != 'PENDING'">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :color="item.country_status == 'ACTIVE' ? 'red' : 'green'"
                  @click="checkItem(item, 'STATUS_CHANGE')"
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  small
                  >{{
                    item.country_status == "ACTIVE" ? "mdi-close" : "mdi-check"
                  }}</v-icon
                >
              </template>
              <span>{{
                item.country_status == "ACTIVE"
                  ? `Deactivate ${item.country_name}`
                  : `Activate ${item.country_name}`
              }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import { GetAllSupportedCountry } from "@/mixins/GetAllSupportedCountry.js";
import CreateCountryDialog from "@/components/SupportedCountries/Dialogs/CreateCountryDialog.vue";
import ActivateDeactivateCountryDialog from "@/components/SupportedCountries/Dialogs/ActivateDeactivateCountryDialog.vue";
export default {
  components: {
    Overlay,
    CreateCountryDialog,
    ActivateDeactivateCountryDialog,
  },
  mixins: [GetAllSupportedCountry],
  data: () => ({
    StoreObj: {},
    overlay: false,
    noDataText: "",
    country_status: "ALL",
    country_statusItems: ["ALL", "ACTIVE", "INACTIVE"],
    dialogCreateCountry: false,
    dialogActivateInactivateCountry: false,
    GetAllSupportedCountryList: [],
    SupportedCountryListHeaders: [
      {
        text: "Country Name",
        value: "country_name",
      },
      {
        text: "Country Code",
        value: "country_code",
      },
      {
        text: "Created On",
        value: "country_created_on",
      },
      {
        text: "Currency",
        value: "currency_symbol",
      },
      {
        text: "Verification Fee",
        value: "verification_fee",
      },
      {
        text: "Status",
        value: "country_status",
      },
      {
        text: "Action",
        value: "Action",
      },
    ],
  }),
  watch: {
    country_status(val) {
      this.GetAllSupportedCountryMethod(val);
    },
  },
  mounted() {
    this.GetAllSupportedCountryMethod(this.country_status);
  },
  methods: {
    dialogActivateInactivateCountryEmit(Toggle) {
      this.dialogActivateInactivateCountry = false;
      if (Toggle == 2) {
        this.GetAllSupportedCountryMethod(this.country_status);
      }
    },
    checkItem(item, action) {
      this.StoreObj = item;
      this.StoreObj.action = action;
      if (item.action == "EDIT") {
        this.dialogCreateCountry = true;
      } else if (item.action == "STATUS_CHANGE") {
        this.dialogActivateInactivateCountry = true;
      }
    },
    dialogCreateCountryEmit(Toggle) {
      this.dialogCreateCountry = false;
      if (Toggle == 2) {
        this.GetAllSupportedCountryMethod(this.country_status);
      }
    },
  },
};
</script>
