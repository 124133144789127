<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="dialogAddIssuerTypes" persistent max-width="400px">
      <v-card>
        <v-toolbar dark dense class="elevation-0" color="secondary">
          <div><v-icon small>mdi-plus</v-icon> Create Issuer Type</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="AddIssuerTypesDialogEmit((Toggle = 1))"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="pb-0">
          <v-form ref="form">
            <v-text-field
              dense
              outlined
              class="field_height field_label_size FontSize mt-4"
              :rules="[(v) => !!v || 'Required']"
              label="Issuer Type"
              v-model="create_issuer_type.issuer_type"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0 pb-4 mr-2">
          <v-spacer></v-spacer>
          <v-btn
            small
            outlined
            class="borderRadius text-capitalize"
            color="secondary"
            @click="AddIssuerTypesDialogEmit((Toggle = 1))"
            ><v-icon small class="mr-1">mdi-close</v-icon>Cancel
          </v-btn>
          <v-btn
            small
            :loading="loading"
            class="borderRadius text-capitalize"
            color="secondary"
            @click="validateFormMethod()"
            ><v-icon small class="mr-1">mdi-check</v-icon>Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Snackbar from "@/components/Extras/Snackbar.vue";
import { CreateIssuerType } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
export default {
  props: {
    dialogAddIssuerTypes: Boolean,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    loading: false,
    overlay: false,
    create_issuer_type: {
      issuer_type: "",
    },
    SnackBarComponent: {},
    creditItems: ["50", "100", "500", "1000", "2500", "10000"],
  }),
  methods: {
    validateFormMethod() {
      if (this.$refs.form.validate()) {
        this.AddIssuerTypestMethod();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fields marked with asterisks(*) are mandatory",
        };
      }
    },
    async AddIssuerTypestMethod() {
      try {
        var inputParams = {
          issuer_type: this.create_issuer_type.issuer_type,
        };
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(CreateIssuerType, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.CreateIssuerType);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.overlay = true;
          this.AddIssuerTypesDialogEmit((this.Toggle = 2));
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
    AddIssuerTypesDialogEmit(Toggle) {
      if (this.$refs.form != undefined) {
        this.$refs.form.reset();
      }
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
