import { GetDefaultConfiguration } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllDefaultCurrencyConfiguration = {
  data() {
    return {
      overlay: false,
      noDataText: "",
      GetAllDefaultCurrencyConfigurationList: [],
      currency: {},
    };
  },
  methods: {
    async GetAllDefaultCurrencyConfigurationMethod() {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(GetDefaultConfiguration, {
            input: {},
          })
        );
        this.GetAllDefaultCurrencyConfigurationList = JSON.parse(
          result.data.GetDefaultConfiguration
        ).data.items;
        console.warn(
          "GetAllDefaultCurrencyConfigurationList",
          this.GetAllDefaultCurrencyConfigurationList
        );
        this.currency = { ...this.GetAllDefaultCurrencyConfigurationList[0] };
        this.currency.rupee = this.currency.rupee.toString().slice(0, 7);
        if (
          this.$route.name == "TransactionLogs" ||
          this.$route.name == "Issuer"
        ) {
          if (this.$route.name == "TransactionLogs") {
            this.GetAllTransactionLogsListMethod();
          } else if (this.$route.name == "Issuer") {
            this.GetAllTransactionLogsListMethod(this.StoreObj.issuer_id);
          }
          this.overlay = true;
        } else {
          this.overlay = false;
        }
      } catch (error) {
        this.overlay = false;
        this.GetAllDefaultCurrencyConfigurationList = [];
        this.noDataText = error.errors[0].message;
      }
    },
  },
};
