<template>
  <div>
    <Overlay :overlay="overlay" />
    <CreateUserDialog
      :dialogCreateBMSUser="dialogCreateBMSUser"
      @clicked="createBMSUsersDialogEmit"
    />
    <v-card class="elevation-0">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <div class="heading1 PrimaryFontColor">Users</div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <v-spacer></v-spacer>
            <v-text-field
              dense
              label="Search BMS Users"
              outlined
              class="FontSize field_label_size field_height borderRadius mt-6 mr-3"
              v-model="search"
            >
              <template v-slot:prepend-inner>
                <v-icon small color="primary" class="mr-1">mdi-magnify</v-icon>
              </template>
            </v-text-field>
            <v-tooltip bottom color="#474747">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  small
                  color="secondary"
                  @click="dialogCreateBMSUser = true"
                  class="borderRadius"
                  ><v-icon small class="mr-1">mdi-plus</v-icon>Create</v-btn
                >
              </template>
              <span>Create BMS User</span>
            </v-tooltip>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="py-0">
        <v-data-table
          dense
          class="elevation-0"
          :headers="userHeaders"
          :items="GetAllBMSUserList"
          :disable-sort="$vuetify.breakpoint.xs ? true : false"
          :search="search"
          :hide-default-footer="GetAllBMSUserList.length == 0"
        >
          <template v-slot:[`item.sl_no`]="{ item }">
            <div class="FontSize">
              {{ GetAllBMSUserList.indexOf(item) + 1 }}
            </div>
          </template>
          <template v-slot:[`item.user_email_id`]="{ item }">
            <div class="FontSize blackAndBoldFont">
              {{ item.user_email_id }}
            </div>
          </template>
          <template v-slot:[`item.user_type`]="{ item }">
            <div class="FontSize">
              {{
                item.user_type.includes("_")
                  ? item.user_type.replace("_", " ")
                  : item.user_type
              }}
            </div>
          </template>
          <template v-slot:[`item.user_status`]="{ item }">
            <div
              class="FontSize font-weight-bold"
              :class="
                item.user_status == 'ACTIVE' ? 'GreenHeader' : 'RedHeader'
              "
            >
              {{ item.user_status }}
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import CreateUserDialog from "@/components/Users/Dialogs/CreateUserDialog.vue";
import { GetAllBMSUsers } from "@/mixins/GetAllBMSUsers.js";
export default {
  components: {
    CreateUserDialog,
    Overlay,
  },
  mixins: [GetAllBMSUsers],
  data: () => ({
    search: "",
    overlay: false,
    dialogCreateBMSUser: false,
    GetAllBMSUserList: [],
    userHeaders: [
      {
        text: "SL No.",
        value: "sl_no",
      },

      {
        text: "Email ID",
        value: "user_email_id",
      },

      {
        text: "User Type",
        value: "user_type",
      },

      {
        text: "Status",
        value: "user_status",
      },
    ],
  }),
  mounted() {
    this.GetAllBMSUserMethod();
  },
  methods: {
    createBMSUsersDialogEmit(Toggle) {
      this.dialogCreateBMSUser = false;
      if (Toggle == 2) {
        this.GetAllBMSUserMethod();
      }
    },
  },
};
</script>
<style>
.RedHeader {
  color: red !important;
}
.GreenHeader {
  color: green !important;
}
.YellowHeader {
  color: yellowgreen !important;
}
.BlueHeader {
  color: blue !important;
}
</style>
